import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { Context } from '../context/context';
import {SliderComponent as RefAppSliderComponent} from '@spi-ref-app-angular/app/slider/slider.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SliderComponent extends RefAppSliderComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
