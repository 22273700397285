import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import { ListComponent as RefAppListComponent } from '@spi-ref-app-angular/app/list/list.component';

declare var SpiSdk: any;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListComponent extends RefAppListComponent implements OnInit {

  constructor(context: Context, protected element: ElementRef) {
    super(context, element);
  }

}
