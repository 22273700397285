import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {CopyPastePageItemComponent as RefAppCopyPastePageItemComponent} from '@spi-ref-app-angular/app/copy-paste-page-item/copy-paste-page-item.component';

@Component({
  selector: 'app-copy-paste-page-item',
  templateUrl: './copy-paste-page-item.component.html',
  styleUrls: ['./copy-paste-page-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CopyPastePageItemComponent extends RefAppCopyPastePageItemComponent {

  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }

}
