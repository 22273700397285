import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {FreeTransformToolComponent as RefAppFreeTransformToolComponent} from '@spi-ref-app-angular/app/free-transform-tool/free-transform-tool.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;
@Component({
  selector: 'app-free-transform-tool',
  templateUrl: './free-transform-tool.component.html',
  styleUrls: ['./free-transform-tool.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FreeTransformToolComponent extends RefAppFreeTransformToolComponent {

  constructor(context:Context) {
    super(context);
  }

}
