import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractPanelComponent as RefAppAbstractPanelComponent} from '../../../node_modules/@silpub/spi-ref-app-angular/src/app/abstract-panel/abstract-panel.component';

@Component({
  template: '',
  encapsulation: ViewEncapsulation.None
})

export class AbstractPanelComponent extends RefAppAbstractPanelComponent implements OnInit {
  constructor(protected element:ElementRef) {
    super(element);
  }
}
