import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {ShadowDistanceComponent as RefAppShadowDistanceComponent} from '@spi-ref-app-angular/app/shadow-distance/shadow-distance.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-shadow-distance',
  templateUrl: './shadow-distance.component.html',
  styleUrls: ['./shadow-distance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShadowDistanceComponent extends RefAppShadowDistanceComponent {

  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }

}
