import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {ShadowToggleComponent as RefAppShadowToggleComponent} from '@spi-ref-app-angular/app/shadow-toggle/shadow-toggle.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-shadow-toggle',
  templateUrl: './shadow-toggle.component.html',
  styleUrls: ['./shadow-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShadowToggleComponent extends RefAppShadowToggleComponent {

  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }

}
