import { Component, OnInit, ViewEncapsulation, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { SpiImageSearchComponent as RefAppSpiImageSearchComponent } from '@spi-ref-app-angular/app/spi-image-search/spi-image-search.component';
import { Context } from "../context/context";
import { ModalService } from '../modal.service';

declare var SpiSdk: any;

@Component({
  selector: 'app-brandfolder-image-search',
  templateUrl: './brandfolder-image-search.component.html',
  styleUrls: ['./brandfolder-image-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrandfolderImageSearchComponent extends RefAppSpiImageSearchComponent {

  public pins = [];

  constructor(context: Context, element: ElementRef, private modalService: ModalService) {
    super(context, element);
  }

  protected init() {
    super.init();
    this.loadPins();
  }

  protected async loadPins() {
    let adapter = SpiSdk.ImageGalleryAdapterFactory.getInstance().getAdapter(this.source);
    this.pins = await adapter.loadPinnedSearches();
  }

  openModal() {
    this.modalService.open('searchPins');
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  search(data) {
    this.searchValueChanged.emit(data.query)
    this.closeModal('searchPins')
  }

}