import {Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {TextPanelComponent as RefAppTextPanelComponent} from '@spi-ref-app-angular/app/text-panel/text-panel.component';

@Component({
  selector: 'app-text-panel',
  templateUrl: './text-panel.component.html',
  styleUrls: ['./text-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TextPanelComponent extends RefAppTextPanelComponent {

}
