import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, Input} from '@angular/core';
import {BuildVersionComponent as RefAppBuildVersionComponent} from '@spi-ref-app-angular/app/build-version/build-version.component';
import {Context} from "../context/context";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-build-version',
  templateUrl: './build-version.component.html',
  styleUrls: ['./build-version.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BuildVersionComponent extends RefAppBuildVersionComponent {

  constructor(context: Context, protected element: ElementRef, private toastrService:ToastrService) {
    super(context, element);
  }

  protected reportSuccess(msg) {
    this.toastrService.success(msg);
  }

  protected reportError(msg) {
    this.toastrService.error(msg);
  }

}
