import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import {Context} from '../context/context';
import {TextColorFillComponent as RefAppTextColorFillComponent} from '@spi-ref-app-angular/app/text-color-fill/text-color-fill.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-text-color-fill',
  templateUrl: './text-color-fill.component.html',
  styleUrls: ['./text-color-fill.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextColorFillComponent extends RefAppTextColorFillComponent implements OnInit {
  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }
}
