import { Component, OnInit, ViewEncapsulation, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import {Context} from '../context/context';
import {SpiImageGalleryComponent as RefAppSpiImageGalleryComponent} from '@spi-ref-app-angular/app/spi-image-gallery/spi-image-gallery.component';

@Component({
  selector: 'app-brandfolder-image-gallery',
  templateUrl: './brandfolder-image-gallery.component.html',
  styleUrls: ['./brandfolder-image-gallery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrandfolderImageGalleryComponent  extends RefAppSpiImageGalleryComponent {

  public showAttachments: boolean = false;

  constructor(context:Context, element: ElementRef) {
    super(context, element);
  }

  public get selectedItem() {
    return this.component ? this.component.selectedItem : null;
  }

  public set selectedItem(value) {
    if (this.component) {
      this.component.selectedItem = value;
    }
  }

  protected init():void {
    super.init();
    this.setupBindings();
  }

  protected setupBindings(): void {
    this.binder.bind(this.component, 'selectedItem', this, 'onAssetSelected');
  }

  protected onAssetSelected(selectedItem):void {
    this.showAttachments = false;

    if (selectedItem && selectedItem.model && selectedItem.model.asset) {
        const { asset } = selectedItem.model;

        if (asset.type === 'bftemplate') {
            const url = `${this.context.config.printUiEditorUrl}${asset.printuiEditorLink}`;
            window.open(url);
        } else {
            this.showAttachments = true;
        }
    }
  }
}
