import {Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {ToolbarComponent as RefToolbarComponent} from '@spi-ref-app-angular/app/toolbar/toolbar.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent extends RefToolbarComponent {

    constructor(context: Context, protected element: ElementRef) {
      super(context, element);
    }
    
}
