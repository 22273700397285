import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import {Context} from '../context/context';
import {StrokeColorFillComponent as RefAppStrokeColorFillComponent} from '@spi-ref-app-angular/app/stroke-color-fill/stroke-color-fill.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-stroke-color-fill',
  templateUrl: './color-fill.component.html',
  styleUrls: ['./color-fill.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StrokeColorFillComponent extends RefAppStrokeColorFillComponent implements OnInit {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }
}
