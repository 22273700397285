import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject, Injector, ReflectiveInjector } from '@angular/core';
import { ModalModule } from 'angular-custom-modal';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DocumentViewComponent } from './document-view/document-view.component';
import { PanelDocumentViewComponent } from './panel-document-view/panel-document-view.component';
import { TouchZoomDocumentViewComponent } from './touch-zoom-document-view/touch-zoom-document-view.component';
import { DragDropFileUploadComponent } from './drag-drop-file-upload/drag-drop-file-upload.component';
import { FreeTransformToolComponent } from './free-transform-tool/free-transform-tool.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { AlertsComponent } from './alerts/alerts.component';
import { FillColorFillComponent } from './fill-color-fill/fill-color-fill.component';
import { StrokeColorFillComponent } from './stroke-color-fill/stroke-color-fill.component';
import { SaveBtnComponent } from './save-btn/save-btn.component';
import { ContinueBtnComponent } from './continue-btn/continue-btn.component';
import { ExitBtnComponent } from './exit-btn/exit-btn.component';
import { PreviewBtnComponent } from './preview-btn/preview-btn.component';
import { SaveTemplateBtnComponent } from './save-template-btn/save-template-btn.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { SliderComponent } from './slider/slider.component';
import { InputSliderComponent } from './input-slider/input-slider.component';
import { StrokeOpacityEditorComponent } from './stroke-opacity-editor/stroke-opacity-editor.component';
import { UndoRedoBtnComponent } from './undo-redo-btn/undo-redo-btn.component';
import { AddPageItemBtnComponent } from './add-page-item-btn/add-page-item-btn.component';
import { StrokeSizeComponent } from './stroke-size/stroke-size.component';
import { Context } from './context/context';
import { FillToggleComponent } from './fill-toggle/fill-toggle.component';
import { StrokeToggleComponent } from './stroke-toggle/stroke-toggle.component';
import { ZoomBtnComponent } from './zoom/zoom-btn.component';
import { RotateBtnComponent } from './rotate-btn/rotate-btn.component';
import { FileUploadBtnComponent } from './file-upload-btn/file-upload-btn.component';
import { FontFamilyComponent } from './font-family/font-family.component';
import { FontStyleBoldComponent } from './font-style-bold/font-style-bold.component';
import { FontStyleItalicComponent } from './font-style-italic/font-style-italic.component';
import { FontStyleUnderlineComponent } from './font-style-underline/font-style-underline.component';
import { HorizontalAlignButton } from './horizontal-align/align-button.component';
import { HorizontalAlignComponent } from './horizontal-align/horizontal-align.component';
import { TextColorFillComponent } from './text-color-fill/text-color-fill.component';
import { ShapeChangeComponent } from './shape-change/shape-change.component';
import { MultiSelectFreeTransformToolComponent } from './multi-select-free-transform-tool/multi-select-free-transform-tool.component';
import { DeletePageItemBtnComponent } from './delete-page-item-btn/delete-page-item-btn.component';
import { RotationSliderComponent } from './rotation-slider/rotation-slider.component';
import { CoordinatesAndSizeComponent } from './coordinates-and-size/coordinates-and-size.component';
import { AbstractPanelComponent } from './abstract-panel/abstract-panel.component';
import { TextPanelComponent } from './text-panel/text-panel.component';
import { StrokePanelComponent } from './stroke-panel/stroke-panel.component';
import { FillPanelComponent } from './fill-panel/fill-panel.component';
import { CoordinatesPanelComponent } from './coordinates-panel/coordinates-panel.component';
import { RotationPanelComponent } from './rotation-panel/rotation-panel.component';
import { OpacityPanelComponent } from './opacity-panel/opacity-panel.component';
import { ShapePanelComponent } from './shape-panel/shape-panel.component';
import { MultiSelectPanelComponent } from './multi-select-panel/multi-select-panel.component';
import { CopyPastePageItemComponent } from './copy-paste-page-item/copy-paste-page-item.component';
import { LayersComponent } from './layers/layers.component';
import { AlignObjectsComponent } from './align-objects/align-objects.component';
import { ValidationComponent } from './validation/validation';
import { ShadowColorComponent } from './shadow-color/shadow-color.component';
import { ShadowDistanceComponent } from './shadow-distance/shadow-distance.component';
import { ShadowPositionComponent } from './shadow-position/shadow-position.component';
import { RulerComponent } from './ruler/ruler.component';
import { GridComponent } from './grid/grid.component';
import { ShadowToggleComponent } from './shadow-toggle/shadow-toggle.component';
import { ShadowPanelComponent } from './shadow-panel/shadow-panel.component';
import { MarginsComponent } from './margins/margins.component';
import { ColumnsComponent } from './columns/columns.component';
import { GuidesComponent } from './guides/guides.component';
import { MultiselectDragComponent } from './multiselect-drag/multiselect-drag.component';
import { FontSizeSliderComponent } from './font-size-slider/font-size-slider.component';
import { BasicPageNavigationComponent } from './basic-page-navigation/basic-page-navigation.component';
import { PanelNavigationComponent } from './panel-navigation/panel-navigation.component';
import { SpinComponent } from './spin/spin.component';
import { QuitPromptComponent } from './quit-prompt/quit-prompt.component';
import {PageNavigationComponent} from './page-navigation/page-navigation.component';
import {ContentOpacityEditorComponent} from './content-opacity-editor/content-opacity-editor.component';
import {FillOpacityEditorComponent} from './fill-opacity-editor/fill-opacity-editor.component';
import {MarginsColumnsEditComponent} from './margins-columns-edit/margins-columns-edit.component';
import {KeyboardMoveComponent} from './keyboard-move/keyboard-move.component';
import {SpiImageGalleryComponent} from './spi-image-gallery/spi-image-gallery.component';
import { SpiImageCategoriesComponent } from './spi-image-categories/spi-image-categories.component';
import { SpiImageSearchComponent } from './spi-image-search/spi-image-search.component';
import {AddTextFrameBtnComponent} from './add-text-frame-btn/add-text-frame-btn.component';
import { BuildVersionComponent } from './build-version/build-version.component';
import { MenuComponent } from './menu/menu.component';
import { SaveStatusComponent } from './save-status/save-status.component';
import { FontCaseComponent } from './font-case/font-case.component';
import { StyleAddComponent } from './style-add/style-add.component';
import { StyleEditComponent } from './style-edit/style-edit.component';
import { StyleColorSelectorComponent } from './style-color-selector/style-color-selector.component';
import { StylePanelComponent } from './style-panel/style-panel.component';
import { StyleSelectComponent } from './style-select/style-select.component';
import { ZindexEditorComponent } from './zindex-editor/zindex-editor.component';
import { HttpClientModule } from '@angular/common/http';
import { BrandfolderImageGalleryComponent } from './brandfolder-image-gallery/brandfolder-image-gallery.component';
import { BrandfolderImageSearchComponent } from './brandfolder-image-search/brandfolder-image-search.component';
import { BrandfolderAttachmentsComponent } from './brandfolder-attachments/brandfolder-attachments.component';
import { BrandfolderImageCategoriesComponent } from './brandfolder-image-categories/brandfolder-image-categories.component';
import { ModalComponent } from './modal-component/modal.component';
import { HyperlinkComponent } from './hyperlink/hyperlink.component';
import { HyperlinkPanelComponent } from './hyperlink-panel/hyperlink-panel.component';
import { FontLineHeightSliderComponent } from './font-line-height-slider/font-line-height-slider.component';
import { FontSizeSliderFormComponent } from './font-size-slider/font-size-slider-form/font-size-slider-form.component';
import { FontLineHeightSliderFormComponent } from './font-line-height-slider/font-line-height-slider-form/font-line-height-slider-form.component';
import { FontFamilyFormComponent } from './font-family/font-family-form/font-family-form.component';
import { ParagraphSpaceComponent } from './paragraph-space/paragraph-space.component';
import { ParagraphSpaceFormComponent } from './paragraph-space/paragraph-space-form/paragraph-space-form.component';
import { GroupsComponent } from './groups/groups.component';
import { ModalContextMenuComponent } from './modal-component/modal-context-menu.component';
import { AddToArticleContextMenuComponent } from './articles/add-to-article-context-menu/add-to-article-context-menu.component';
import { ArticleContextButtonComponent } from './articles/article-context-btn/article-context-btn.component';
import { ConfirmComponent } from './modal-component/confirm/confirm.component';
import { InsetSpacingComponent } from './inset-spacing/inset-spacing.component';
import { InsetSpacingPanelComponent } from './inset-spacing-panel/inset-spacing-panel.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ListComponent } from './list/list.component';

@NgModule({
  declarations: [
    AppComponent,
    DocumentViewComponent,
    PanelDocumentViewComponent,
    TouchZoomDocumentViewComponent,
    DragDropFileUploadComponent,
    FreeTransformToolComponent,
    ImageGalleryComponent,
    SpiImageGalleryComponent,
    AlertsComponent,
    FillColorFillComponent,
    StrokeColorFillComponent,
    PageNavigationComponent,
    SaveBtnComponent,
    ContinueBtnComponent,
    ExitBtnComponent,
    PreviewBtnComponent,
    SaveTemplateBtnComponent,
    InputNumberComponent,
    SliderComponent,
    InputSliderComponent,
    ContentOpacityEditorComponent,
    StrokeOpacityEditorComponent,
    FillOpacityEditorComponent,
    UndoRedoBtnComponent,
    AddPageItemBtnComponent,
    DeletePageItemBtnComponent,
    StrokeSizeComponent,
    FillToggleComponent,
    StrokeToggleComponent,
    ZoomBtnComponent,
    RotateBtnComponent,
    FileUploadBtnComponent,
    FontFamilyComponent,
    FontStyleBoldComponent,
    FontStyleItalicComponent,
    FontStyleUnderlineComponent,
    HorizontalAlignButton,
    HorizontalAlignComponent,
    TextColorFillComponent,
    MultiSelectFreeTransformToolComponent,
    ShapeChangeComponent,
    RotationSliderComponent,
    CoordinatesAndSizeComponent,
    AbstractPanelComponent,
    TextPanelComponent,
    StrokePanelComponent,
    FillPanelComponent,
    CoordinatesPanelComponent,
    RotationPanelComponent,
    OpacityPanelComponent,
    ShapePanelComponent,
    MultiSelectPanelComponent,
    CopyPastePageItemComponent,
    LayersComponent,
    AlignObjectsComponent,
    ValidationComponent,
    ShadowColorComponent,
    ShadowDistanceComponent,
    ShadowPositionComponent,
    RulerComponent,
    GridComponent,
    ShadowToggleComponent,
    ShadowPanelComponent,
    MarginsComponent,
    ColumnsComponent,
    MarginsColumnsEditComponent,
    GuidesComponent,
    MultiselectDragComponent,
    KeyboardMoveComponent,
    AbstractPanelComponent,
    FontSizeSliderComponent,
    BasicPageNavigationComponent,
    PanelNavigationComponent,
    SpinComponent,
    QuitPromptComponent,
    SpiImageCategoriesComponent,
    SpiImageSearchComponent,
    AddTextFrameBtnComponent,
    BuildVersionComponent,
    MenuComponent,
    SaveStatusComponent,
    FontCaseComponent,
    StyleAddComponent,
    StyleEditComponent,
    StyleColorSelectorComponent,
    StylePanelComponent,
    StyleSelectComponent,
    ZindexEditorComponent,
    BrandfolderImageGalleryComponent,
    BrandfolderImageSearchComponent,
    BrandfolderAttachmentsComponent,
    BrandfolderImageCategoriesComponent,
    ModalComponent,
    HyperlinkComponent,
    HyperlinkPanelComponent,
    FontLineHeightSliderComponent,
    FontSizeSliderFormComponent,
    FontLineHeightSliderFormComponent,
    FontFamilyFormComponent,
    ParagraphSpaceComponent,
    ParagraphSpaceFormComponent,
    GroupsComponent,
    ModalContextMenuComponent,
    AddToArticleContextMenuComponent,
    ArticleContextButtonComponent,
    ConfirmComponent,
    InsetSpacingComponent,
    InsetSpacingPanelComponent,
    ToolbarComponent,
    ListComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ModalModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    Context,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private context: Context) {

  }
}
