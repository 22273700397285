// @ts-ignore
const params = (new URL(document.location)).searchParams;
const SERVICE_URL = params.get('be') || '/';
const ACCESS_TOKEN = params.get('at') || '_';
const EPUB_WEB_BASE_URL = params.get('wau') || '/';
const RELATIVE_NUCLEUS_PATH = params.get('rnp');
const URL_PATH = `/api/sd/v1`;
const BASE_URL = `${SERVICE_URL}${URL_PATH}`;
const DAM_ACTIVE = params.get('dam');
import {BASIC_PARAMS} from './environment.basic';

const BRANDFOLDER_API_URL = `${EPUB_WEB_BASE_URL}/api/v1/integration`;
const PRINT_UI_EDITOR_URL = `https://wecreate.4lpi.com`;
const DOC_VIEWER_URL = `https://wecreate.4lpi.com/viewer/lpi/`;

export const environment = {
  ...BASIC_PARAMS,
  backendAdminPath: RELATIVE_NUCLEUS_PATH,
  production: true,
  isDebugMode: false,
  urlPath: URL_PATH,
  baseUrl: BASE_URL,
  webAssetManagerUrl: BASE_URL,
  templateApiUrl: BASE_URL,
  jobsApiUrl: BASE_URL,
  instanceApiUrl: BASE_URL,
  authenticationApiUrl: BASE_URL,
  brandfolderApiUrl: BRANDFOLDER_API_URL,
  docViewerUrl: DOC_VIEWER_URL,
  printUiEditorUrl: PRINT_UI_EDITOR_URL,
  authToken: ACCESS_TOKEN,
  userAccessToken: '_',
  templateId: '1301',
  displayLayers: true,
  damActive: DAM_ACTIVE
};
