import { Component, OnInit, ViewEncapsulation, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import {Context} from '../context/context';
import {SpiImageGalleryRecordSet} from '@silpub/spi-core';
import {SpiImageGalleryComponent as RefAppSpiImageGalleryComponent} from '@spi-ref-app-angular/app/spi-image-gallery/spi-image-gallery.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-brandfolder-attachments',
  templateUrl: './brandfolder-attachments.component.html',
  styleUrls: ['./brandfolder-attachments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrandfolderAttachmentsComponent extends RefAppSpiImageGalleryComponent {

  @Input()
  parentItem:any;

  @Output()
  parentItemChange = new EventEmitter<number>();

  constructor(context:Context, element: ElementRef) {
    super(context, element);
  }

  ngAfterViewInit() {
    this.component.collection.categoriesFilter.selectedCategories.push(SpiSdk.ImageCategory.ALL_CATEGORIES_ID);
  }

  ngOnChanges(changes) {
    if (changes && changes.parentItem) {
        this.updateComponentState();
    }
  }

  protected init():void {
    super.init();
    this.setupBindings();
  }

  protected setupBindings(): void {
    this.binder.bind(this.component, 'selectedItem', this, 'onAssetSelected');
  }

  protected onAssetSelected(selectedItem):void {
    if (selectedItem && selectedItem.model && selectedItem.model.asset) {
        const { asset } = selectedItem.model;

        if (asset.extension === 'doc' || asset.extension === 'pdf') {
            const url = `${this.context.config.docViewerUrl}${asset.id}`;
            window.open(url);
        }
    }
  }

  protected updateComponentState():void {
    if (this.parentItem) {
        const { id, attachmentCount, type } = this.parentItem.model.asset;
        if (type !== 'bftemplate') {
            this.loadAttachments(id, attachmentCount);
        }
    }
  }

  protected loadAttachments(assetId, attachmentCount){
    this.component.collection.search = assetId;
    this.component.collection.recordsPerPage = attachmentCount;;
  }

  public onBackClick() {
      this.parentItem = null;
      this.parentItemChange.emit(this.parentItem);
  }

}
