import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {BasicPageNavigationComponent as RefAppBasicPageNavigationComponent} from '../../../node_modules/@silpub/spi-ref-app-angular/src/app/basic-page-navigation/basic-page-navigation.component';

@Component({
  selector: 'app-basic-page-navigation',
  templateUrl: './basic-page-navigation.component.html',
  styleUrls: ['./basic-page-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicPageNavigationComponent extends RefAppBasicPageNavigationComponent implements OnInit {
  constructor(context:Context) {
    super(context);
  }
}
