import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {SpinComponent as RefAppSpinComponent} from '@spi-ref-app-angular/app/spin/spin.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-spin',
  templateUrl: './spin.component.html',
  styleUrls: ['./spin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinComponent extends RefAppSpinComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
