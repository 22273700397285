import {Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input} from '@angular/core';
import {Context} from '../context/context';
import {SpiDropdownComponent} from '@spi-ref-app-angular/app/spi-dropdown/spi-dropdown.component';

declare var SpiSdk: any;

@Component({
  selector: 'app-font-case',
  templateUrl: './font-case.component.html',
  styleUrls: ['./font-case.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FontCaseComponent extends SpiDropdownComponent implements OnInit {
  protected editor = new SpiSdk.FontCaseEditor();

  constructor(context: Context, protected element: ElementRef) {
    super(context, element);
  }

  onInstanceReady (event) {
    this.initOptions();
  }

  protected initView(): void {
    super.initView();
    this.initEditor();
    this.binder.bind(SpiSdk.Model.textSelectionModel, 'typographicCase', this, 'updateComponentState');
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.ControlEvent.CONTROL_SELECTED, this, 'updateComponentState');
    this.disable();
  }

  protected initEditor(): void {
    this.editor.model = SpiSdk.Model;
    this.editor.selectionManager = SpiSdk.SelectionManager;
  }

  protected initOptions() {
    const data = SpiSdk.Model.config.fontCaseOptions;
    let options = [];

    for (let i = 0; i < data.length; ++i) {
      const value = data[i];
      options.push(value);
    }

    this.options = options;
  }

  protected optionSelectedAction (option) {
    this.editor.typographicCase = option.value;
    this.editor.updateTransforms();
  }

  protected updateComponentState(): void {
    const fontCase = SpiSdk.Model.textSelectionModel.typographicCase;
    // Text editing components don't support operations on multiselected elements
    const control = SpiSdk.SelectionManager.selectedControls.length() == 1
                    ? SpiSdk.SelectionManager.getSelectedControl()
                    : null;

    if (control !== null &&
        control !== undefined &&
        control.pageItem instanceof SpiSdk.TextPageItem &&
        control.pageItem.fontChangeable) {
      this.selectOptionByValue(fontCase);
      this.enable();
    } else {
      this.selectOptionByValue('');
      this.disable();
    }
  }

  protected destroy(): void {
    super.destroy();
    this.editor = null;
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.ControlEvent.CONTROL_SELECTED, this, 'updateComponentState');
  }
}
