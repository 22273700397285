import {Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {CoordinatesPanelComponent as RefAppCoordinatesPanelComponent} from '@spi-ref-app-angular/app/coordinates-panel/coordinates-panel.component';


@Component({
  selector: 'app-coordinates-panel',
  templateUrl: './coordinates-panel.component.html',
  styleUrls: ['./coordinates-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CoordinatesPanelComponent extends RefAppCoordinatesPanelComponent {
}
