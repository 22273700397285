import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {FillOpacityEditorComponent as RefAppFillOpacityEditorComponent} from '@spi-ref-app-angular/app/fill-opacity-editor/fill-opacity-editor.component';

@Component({
  selector: 'app-fill-opacity-editor',
  templateUrl: './opacity-editor.component.html',
  styleUrls: ['./opacity-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FillOpacityEditorComponent extends RefAppFillOpacityEditorComponent {

  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }

}
