import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {AlertsComponent as RefAppAlertsComponent} from '@spi-ref-app-angular/app/alerts/alerts.component';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertsComponent extends RefAppAlertsComponent implements OnInit {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
