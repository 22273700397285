import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {SaveTemplateBtnComponent as RefAppSaveTemplateBtnComponent} from '@spi-ref-app-angular/app/save-template-btn/save-template-btn.component';

//dereference the SDK. This ensure we don't get duplicate SDK code in the main bundle
declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-save-template-btn',
  templateUrl: './save-template-btn.component.html',
  styleUrls: ['./save-template-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SaveTemplateBtnComponent extends RefAppSaveTemplateBtnComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
