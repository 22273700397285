import { Component, OnInit, Input, Output, ViewChild, AfterViewInit, EventEmitter, ViewEncapsulation, OnDestroy, ElementRef } from '@angular/core';
import { ModalService } from '../modal.service';
import { ModalContextMenuComponent as RefAppModalContextMenuComponent } from '@spi-ref-app-angular/app/modal/modal-context-menu.component';

@Component({
  selector: 'jw-modal-context-menu',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalContextMenuComponent extends RefAppModalContextMenuComponent {
  constructor(protected modalService: ModalService, protected el: ElementRef) {
    super(modalService, el);
  }
}
