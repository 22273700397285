import { Component, ViewEncapsulation, ElementRef} from '@angular/core';
import {Context} from '../context/context';
import {ShapeChangeComponent as RefAppShapeChangeComponent} from '@spi-ref-app-angular/app/shape-change/shape-change.component';

@Component({
  selector: 'app-shape-change',
  templateUrl: './shape-change.component.html',
  styleUrls: ['./shape-change.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class ShapeChangeComponent extends RefAppShapeChangeComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
