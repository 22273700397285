import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {DragDropFileUploadComponent as RefAppDragDropFileUploadComponent} from '@spi-ref-app-angular/app/drag-drop-file-upload/drag-drop-file-upload.component';

@Component({
  selector: 'app-drag-drop-file-upload',
  templateUrl: './drag-drop-file-upload.component.html',
  styleUrls: ['./drag-drop-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DragDropFileUploadComponent extends RefAppDragDropFileUploadComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
