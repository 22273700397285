type StoryCreatorCommandName =
  'getState'
  | 'changeSetting'
  | 'openMarginsSettings'
  | 'save'
  | 'preview'
  | 'undo'
  | 'redo'
  | 'paste'
  | 'zoomIn'
  | 'zoomOut'
  | 'zoomReset'
  | 'firstPage'
  | 'lastPage'
  | 'previousPage'
  | 'setCurrentPage'
  | 'nextPage';

const viewSettingMap = {
  snapToGrid: 'view-option-snap-grid',
  snapToGuides: 'view-option-snap-guides',
  snapToMargins: 'view-option-snap-margins',
  snapToColumns: 'view-option-snap-columns',
  showGrid: 'view-option-show-grid',
  showMargins: 'view-option-show-margins',
  showColumns: 'view-option-show-columns',
  showGuides: 'view-option-show-guides'
};
declare var SpiSdk: any;

let onChangeEmitTO: any = null;

const eventListeners = {
  /** Preview event dispatch after project is saved */
  previewAfterSaveTrigger: () => {
    /** Spawn new instance of preview button and run preview script */
    const previewBtn = new SpiSdk.PreviewBtn();
    previewBtn.onPreviewInstanceSaved();

    /** Remove instance saved listener to prevent requesting preview after each save */
    SpiSdk.EventDispatcher.removeEventListener(
      SpiSdk.InstanceEvent.INSTANCE_SAVED,
      eventListeners,
      'previewAfterSaveTrigger'
    );
  },

  /** Change made to the project */
  onChange: () => {
    if (onChangeEmitTO) {
      clearTimeout(onChangeEmitTO);
    }
    onChangeEmitTO = setTimeout(() => window.parent.postMessage({ command: 'onChange', data: {} }, '*'), 3000);
  },

  /** On instance saved event to unblock save button in parent iframe */
  onSaved: () => window.parent.postMessage({ command: 'onSaved', data: {} }, '*'),

  /** Preview ready event, send preview URL to parent iframe */
  onPreview: (e) => window.parent.postMessage({ command: 'onPreview', data: e.payload.job }, '*'),

  /** Project loaded event, unblock action buttons in parent iframe */
  projectLoaded: () => {
    window.parent.postMessage({
      command: 'projectLoaded', data: {
        settings: {
          snapToGrid: !!SpiSdk.Model.documentViewModel.snapToGrid,
          snapToGuides: !!SpiSdk.Model.documentViewModel.snapToGuides,
          snapToMargins: !!SpiSdk.Model.documentViewModel.snapToMargins,
          snapToColumns: !!SpiSdk.Model.documentViewModel.snapToColumns,
          showGrid: !!SpiSdk.Model.documentViewModel.showGrid,
          showMargins: !!SpiSdk.Model.documentViewModel.showMargins,
          showColumns: !!SpiSdk.Model.documentViewModel.showColumns,
          showGuides: !!SpiSdk.Model.documentViewModel.showGuides
        }
      }
    }, '*');
  },

  /** Page loaded event, unblock navigation buttons in parent iframe */
  pageLoaded: () => window.parent.postMessage({ command: 'pageLoaded', data: {} }, '*'),

  /** Error listener */
  designerPreviewError: (e) => {
    /** Preview job error, for some reason error event is dispatched when pulling request is made to check job status */
    if (e && e.payload && e.payload.job) {
      if (e.payload.job.error && e.payload.job.error.length) {
        window.parent.postMessage({ command: 'designerPreviewError', data: {} }, '*');
      }
    } else {
      /** Condition to check all other error events */
      if (e && e.payload && e.payload) {
        window.parent.postMessage({ command: 'designerPreviewError', data: {} }, '*');
      }
    }
  }
};

document.addEventListener('DOMContentLoaded', () => {
  SpiSdk.EventDispatcher.addEventListener(
    SpiSdk.InstanceEvent.INSTANCE_SAVED,
    eventListeners,
    'onSaved'
  );

  SpiSdk.EventDispatcher.addEventListener(
    SpiSdk.InstanceEvent.PREVIEW_READY,
    eventListeners,
    'onPreview'
  );

  SpiSdk.EventDispatcher.addEventListener(
    SpiSdk.InstanceEvent.INSTANCE_READY,
    eventListeners,
    'projectLoaded'
  );

  SpiSdk.EventDispatcher.addEventListener(
    SpiSdk.DocumentViewEvent.PAGE_ADDED_TO_DOM,
    eventListeners,
    'pageLoaded'
  );

  SpiSdk.EventDispatcher.addEventListener(
    SpiSdk.InstanceEvent.ERROR,
    eventListeners,
    'designerPreviewError'
  );

  SpiSdk.EventDispatcher.addEventListener(
    SpiSdk.ActionErrorEvent.ERROR,
    eventListeners,
    'designerPreviewError'
  );

  SpiSdk.EventDispatcher.addEventListener(
    SpiSdk.UndoRedoEvent.ADD_MEMENTO,
    eventListeners,
    'onChange'
  );
});

export const onIframeMessage = (e) => {
  const data: { command: StoryCreatorCommandName, setting?: string | number } = e.data;

  switch (data.command) {
    case 'preview': {
      SpiSdk.EventDispatcher.addEventListener(
        SpiSdk.InstanceEvent.INSTANCE_SAVED,
        eventListeners,
        'previewAfterSaveTrigger'
      );
      SpiSdk.EventDispatcher.dispatch(new SpiSdk.LayoutEvent(SpiSdk.LayoutEvent.SYNC_ALL, {
        instanceId: SpiSdk.Model.sessionModel.currentInstance.instanceId
      }));
      SpiSdk.EventDispatcher.dispatch(new SpiSdk.InstanceEvent(SpiSdk.InstanceEvent.SAVE_INSTANCE, {
        instance: SpiSdk.Model.sessionModel.currentInstance,
        instanceId: SpiSdk.Model.sessionModel.currentInstance.id
      }));
      break;
    }

    case 'save': {
      SpiSdk.EventDispatcher.dispatch(new SpiSdk.LayoutEvent(SpiSdk.LayoutEvent.SYNC_ALL, {
        instanceId: SpiSdk.Model.sessionModel.currentInstance.instanceId
      }));
      SpiSdk.EventDispatcher.dispatch(new SpiSdk.InstanceEvent(SpiSdk.InstanceEvent.SAVE_INSTANCE, {
        instance: SpiSdk.Model.sessionModel.currentInstance,
        instanceId: SpiSdk.Model.sessionModel.currentInstance.id
      }));
      break;
    }
    case 'undo': {
      const undoBtn = document.getElementsByTagName('app-undo-redo-btn')[0];
      if (undoBtn) {
        const label = undoBtn.querySelector('label');
        if (label) {
          label.click();
        }
      }
      break;
    }
    case 'redo': {
      const redoBtn = document.getElementsByTagName('app-undo-redo-btn')[1];
      if (redoBtn) {
        const label = redoBtn.querySelector('label');
        if (label) {
          label.click();
        }
      }
      break;
    }
    case 'zoomOut': {
      const zoomOutBtn = document.getElementsByTagName('app-zoom-btn')[0];
      if (zoomOutBtn) {
        const label = zoomOutBtn.querySelector('button');
        if (label) {
          label.click();
        }
      }
      break;
    }
    case 'zoomIn': {
      const zoomInBtn = document.getElementsByTagName('app-zoom-btn')[1];
      if (zoomInBtn) {
        const label = zoomInBtn.querySelector('button');
        if (label) {
          label.click();
        }
      }
      break;
    }
    case 'zoomReset': {
      const zoomResetBtn = document.getElementsByTagName('app-zoom-btn')[2];
      if (zoomResetBtn) {
        const label = zoomResetBtn.querySelector('label');
        if (label) {
          label.click();
        }
      }
      break;
    }
    case 'firstPage': {
      const firstPageBtn = document.getElementsByClassName('first-button')[0] as HTMLElement;
      if (firstPageBtn) {
        firstPageBtn.click();
      }
      break;
    }
    case 'lastPage': {
      const lastPageBtn = document.getElementsByClassName('last-button')[0] as HTMLElement;
      if (lastPageBtn) {
        lastPageBtn.click();
      }
      break;
    }
    case 'previousPage': {
      const previousPageBtn = document.getElementsByClassName('previous-button')[0] as HTMLElement;
      if (previousPageBtn) {
        previousPageBtn.click();
      }
      break;
    }
    case 'nextPage': {
      const nextPageBtn = document.getElementsByClassName('next-button')[0] as HTMLElement;
      if (nextPageBtn) {
        nextPageBtn.click();
      }
      break;
    }

    case 'changeSetting': {
      const settingInput = document.getElementById(viewSettingMap[data.setting]) as HTMLElement;
      if (settingInput) {
        settingInput.click();
      }
      break;
    }

    case 'openMarginsSettings': {
      const settingInput = document.getElementById('margins-and-columns-settings') as HTMLElement;
      if (settingInput) {
        settingInput.click();
      }
      break;
    }

    case 'setCurrentPage': {
      SpiSdk.EventDispatcher.dispatch(
        new SpiSdk.LayoutEvent(SpiSdk.LayoutEvent.GET_AND_SELECT_LAYOUT,
          {
            layoutNum: (data.setting as number) - 1,
            instanceId: SpiSdk.Model.sessionModel.currentInstance.id
          }
        )
      );
      break;
    }

    case 'paste': {
      SpiSdk.EventDispatcher.dispatch(new SpiSdk.DocumentViewEvent(SpiSdk.DocumentViewEvent.PASTE_PAGE_ITEMS, {}));
      break;
    }

    case 'getState': {
      try {
        const pagination = {
          current: SpiSdk.Model.documentViewModel.selectedPage ? SpiSdk.Model.documentViewModel.selectedPage.number : 0,
          total: SpiSdk.Model.sessionModel.currentInstance ? SpiSdk.Model.sessionModel.currentInstance.pages.length() : 0
        };
        // @ts-ignore
        const saved = document.getElementsByTagName('app-save-status')[0].innerText;
        const version = SpiSdk.___DESIGNER_VERSION___;

        window.parent.postMessage({ command: 'getStateResponse', data: { pagination, saved, version } }, '*');
      } catch (e) {
        return null;
      }
      break;
    }
  }
};
