import {Component, OnInit, ViewEncapsulation, ElementRef, ViewChild} from '@angular/core';
import {Context} from '../context/context';
import {DocumentViewComponent} from '../document-view/document-view.component';
import { ToastrService } from 'ngx-toastr';

//dereference the SDK. This ensure we don't get duplicate SDK code in the main bundle
declare var SpiSdk:any;

@Component({
  selector: 'panel-spidocview',
  templateUrl: '../document-view/document-view.component.html',
  styleUrls: ['../document-view/document-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PanelDocumentViewComponent extends DocumentViewComponent implements OnInit {
  constructor(context:Context, toastrService:ToastrService) {
    super(context, toastrService);
  }

  protected getViewComponent():any{
    return new SpiSdk.PanelDocumentView();
  }
}
