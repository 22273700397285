import {Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input} from '@angular/core';
import {Context} from '../context/context';
import {KeyboardMoveComponent as RefAppKeyboardMoveComponent} from '@spi-ref-app-angular/app/keyboard-move/keyboard-move.component';

declare var SpiSdk: any;

@Component({
  selector: 'app-keyboard-move',
  templateUrl: './keyboard-move.component.html',

  encapsulation: ViewEncapsulation.None
})
export class KeyboardMoveComponent extends RefAppKeyboardMoveComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
