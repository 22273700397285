import {Component, OnInit, ViewEncapsulation, ElementRef, ViewChildren, QueryList} from '@angular/core';
import {Context} from '../context/context';
import {HorizontalAlignButton} from './align-button.component';
import {HorizontalAlignComponent as RefAppHorizontalAlignComponent} from '@spi-ref-app-angular/app/horizontal-align/horizontal-align.component';

declare var SpiSdk: any;
declare var SpiRef: any;
declare var $: any;

@Component({
  selector: 'app-horizontal-align',
  templateUrl: './horizontal-align.component.html',
  styleUrls: ['./horizontal-align.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class HorizontalAlignComponent extends RefAppHorizontalAlignComponent {
  @ViewChildren(HorizontalAlignButton) protected buttons: QueryList<HorizontalAlignButton>;

  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
