import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {InputSliderComponent as RefAppInputSliderComponent} from '@spi-ref-app-angular/app/input-slider/input-slider.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-input-slider',
  templateUrl: './input-slider.component.html',
  styleUrls: ['./input-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputSliderComponent extends RefAppInputSliderComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
