import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Context } from '../context/context';
import { ModalService } from '../modal.service';
import { HyperlinkComponent as RefAppHyperlinkComponent } from '@spi-ref-app-angular/app/hyperlink/hyperlink.component';

@Component({
  selector: 'app-hyperlink',
  templateUrl: './hyperlink.component.html',
  styleUrls: ['./hyperlink.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HyperlinkComponent extends RefAppHyperlinkComponent implements OnInit {
    constructor(context: Context, protected modalService: ModalService) {
      super(context, modalService);
    }
}
