import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {StyleAddComponent} from '../style-add/style-add.component';

// dereference the SDK. This ensure we don't get duplicate SDK code in the main bundle
declare var SpiSdk: any;

@Component({
  selector: 'app-style-edit',
  templateUrl: '../style-add/style-add.component.html',
  styleUrls: ['../style-add/style-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StyleEditComponent extends StyleAddComponent {
  @ViewChild('styleName', {static: true})
  styleName: ElementRef;

  constructor(context: Context) {
    super(context);
    this.newStyle = false;
  }

  public onEditStyle(event) {
    const {style} = event.payload;
    this.styleName.nativeElement.value = SpiSdk.StringUtil.convertCharCodesToString(style.name).split('_')[0];

   this.selectStyle(style);
  }

  protected addEventListeners(): void {
    super.addEventListeners();
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.StyleListView.EDIT_STYLE, this, 'onEditStyle');
  }

  protected removeEventListeners(): void {
    super.removeEventListeners()
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.StyleListView.EDIT_STYLE, this, 'onEditStyle');
  }

  protected getViewComponent(): any {
    return new SpiSdk.EditStyleComponent();
  }

}
