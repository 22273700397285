import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {UndoRedoBtnComponent as RefAppUndoRedoBtnComponent} from '@spi-ref-app-angular/app/undo-redo-btn/undo-redo-btn.component';

//dereference the SDK. This ensure we don't get duplicate SDK code in the main bundle
declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-undo-redo-btn',
  templateUrl: './undo-redo-btn.component.html',
  styleUrls: ['./undo-redo-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UndoRedoBtnComponent extends RefAppUndoRedoBtnComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
