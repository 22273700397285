import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {MultiSelectFreeTransformToolComponent as RefAppMultiSelectFreeTransformToolComponent} from '@spi-ref-app-angular/app/multi-select-free-transform-tool/multi-select-free-transform-tool.component';

@Component({
  selector: 'app-multi-select-free-transform-tool',
  templateUrl: '../free-transform-tool/free-transform-tool.component.html',
  styleUrls: ['../free-transform-tool/free-transform-tool.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectFreeTransformToolComponent extends RefAppMultiSelectFreeTransformToolComponent {

  constructor(context:Context) {
    super(context);
  }
}
