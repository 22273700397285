import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfirmComponent as RefAppConfirmComponent } from '@spi-ref-app-angular/app/modal/confirm/confirm.component';

@Component({
  selector: 'jw-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmComponent extends RefAppConfirmComponent {
}
