import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {RotationSliderComponent as RefAppRotationSliderComponent} from '@spi-ref-app-angular/app/rotation-slider/rotation-slider.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-rotation-slider',
  templateUrl: './rotation-slider.component.html',
  styleUrls: ['./rotation-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RotationSliderComponent extends RefAppRotationSliderComponent {

  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }

}
