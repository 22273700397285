import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import {Context} from '../context/context';
import {ShadowColorComponent as RefAppShadowColorComponent} from '@spi-ref-app-angular/app/shadow-color/shadow-color.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-shadow-color',
  templateUrl: './shadow-color.component.html',
  styleUrls: ['./shadow-color.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShadowColorComponent extends RefAppShadowColorComponent implements OnInit {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }
}
