import {Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input} from '@angular/core';
import {Context} from '../context/context';
import {MultiselectDragComponent as RefAppMultiselectDragComponent} from '@spi-ref-app-angular/app/multiselect-drag/multiselect-drag.component';

declare var SpiSdk: any;

@Component({
  selector: 'app-multiselect-drag',
  templateUrl: './multiselect-drag.component.html',
  styleUrls: ['./multiselect-drag.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class MultiselectDragComponent extends RefAppMultiselectDragComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
