import {Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {OpacityPanelComponent as RefAppOpacityPanelComponent} from '@spi-ref-app-angular/app/opacity-panel/opacity-panel.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-opacity-panel',
  templateUrl: './opacity-panel.component.html',
  styleUrls: ['./opacity-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class OpacityPanelComponent extends RefAppOpacityPanelComponent {

}
