import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {StrokeSizeComponent as RefAppStrokeSizeComponent} from '@spi-ref-app-angular/app/stroke-size/stroke-size.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-stroke-size',
  templateUrl: './stroke-size.component.html',
  styleUrls: ['./stroke-size.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StrokeSizeComponent extends RefAppStrokeSizeComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
