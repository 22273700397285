import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {FontLineHeightSliderComponent as RefAppFontLineHeightSliderComponent} from '@spi-ref-app-angular/app/font-line-height-slider/font-line-height-slider.component';

@Component({
  selector: 'app-font-line-height-slider',
  templateUrl: '../shared/templates/input-slider-base.component.html',
  styleUrls: ['./font-line-height-slider.component.scss']
})
export class FontLineHeightSliderComponent extends RefAppFontLineHeightSliderComponent implements OnInit {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
