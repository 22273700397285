import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {DeletePageItemBtnComponent as RefAppDeletePageItemBtnComponent} from '@spi-ref-app-angular/app/delete-page-item-btn/delete-page-item-btn.component';

@Component({
  selector: 'app-delete-page-item-btn',
  templateUrl: './delete-page-item-btn.component.html',
  styleUrls: ['./delete-page-item-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DeletePageItemBtnComponent extends RefAppDeletePageItemBtnComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
