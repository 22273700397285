import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {ContentOpacityEditorComponent as RefAppContentOpacityEditorComponent} from '@spi-ref-app-angular/app/content-opacity-editor/content-opacity-editor.component';

@Component({
  selector: 'app-content-opacity-editor',
  templateUrl: './opacity-editor.component.html',
  styleUrls: ['./opacity-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentOpacityEditorComponent extends RefAppContentOpacityEditorComponent {

  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }

}
