import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {QuitPromptComponent as RefAppQuitPromptComponent} from '@spi-ref-app-angular/app/quit-prompt/quit-prompt.component';

declare var SpiSdk: any;
declare var SpiRef: any;
declare var $: any;

@Component({
  selector: 'app-quit-prompt',
  templateUrl: './quit-prompt.component.html',
  encapsulation: ViewEncapsulation.None
})
export class QuitPromptComponent extends RefAppQuitPromptComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
