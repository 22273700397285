import { Component, OnInit, Input, Output, ViewChild, AfterViewInit, EventEmitter, ViewEncapsulation, OnDestroy, ElementRef } from '@angular/core';
import { ModalService } from '../modal.service';
import { ModalComponent as RefAppModalComponent } from '@spi-ref-app-angular/app/modal/modal.component';

@Component({
  selector: 'jw-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent extends RefAppModalComponent implements OnInit, OnDestroy {
  constructor(protected modalService: ModalService, protected el: ElementRef) {
    super(modalService, el);
  }
}


