import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import {Context} from '../context/context';
import {MarginsColumnsEditComponent as RefAppMarginsColumnsEditComponent} from '@spi-ref-app-angular/app/margins-columns-edit/margins-columns-edit.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-margins-columns-edit',
  templateUrl: './margins-columns-edit.component.html',
  styleUrls: ['./margins-columns-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarginsColumnsEditComponent extends RefAppMarginsColumnsEditComponent implements OnInit {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }
}
