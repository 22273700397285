import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {TextPanelComponent} from '@app/text-panel/text-panel.component';
import {StyleAddComponent} from "../style-add/style-add.component";
import {StyleEditComponent} from "../style-edit/style-edit.component";

// dereference the SDK. This ensure we don't get duplicate SDK code in the main bundle
declare var SpiSdk: any;

@Component({
  selector: 'app-style-panel',
  templateUrl: './style-panel.component.html',
  styleUrls: ['./style-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StylePanelComponent extends TextPanelComponent implements OnInit, OnDestroy {
  @ViewChild('addBtn', {static: true})
  addBtn: ElementRef;
  @ViewChild('addStylePanel', {static: true})
  addStylePanel: StyleAddComponent;
  @ViewChild('editStylePanel', {static: true})
  editStylePanel: StyleEditComponent;

  public onAddBtnClickProxy = this.onAddBtnClick.bind(this);

  public onEditStyle() {
    // @ts-ignore
    this.addStylePanel.hide();
    // @ts-ignore
    this.editStylePanel.show();
  }

  public onAddBtnClick() {
    // @ts-ignore
    this.addStylePanel.reset();
    this.addStylePanel.show();
    // @ts-ignore
    this.editStylePanel.hide();
  }

  ngOnInit() {
    super.ngOnInit();
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.StyleListView.EDIT_STYLE, this, 'onEditStyle');
    this.addBtn.nativeElement.addEventListener('click', this.onAddBtnClickProxy);
  }

  ngOnDestroy() {
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.StyleListView.EDIT_STYLE, this, 'onEditStyle');
    this.addBtn.nativeElement.removeEventListener('click', this.onAddBtnClickProxy);
  }
}
