import {Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input} from '@angular/core';
import {Context} from '../context/context';
import {MarginsComponent as RefAppMarginsComponent} from '@spi-ref-app-angular/app/margins/margins.component';

declare var SpiSdk: any;
declare var FriesensMarginsView: any;

@Component({
  selector: 'app-margins',
  templateUrl: './margins.component.html',
  styleUrls: ['./margins.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class MarginsComponent extends RefAppMarginsComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
