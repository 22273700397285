import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {FontStyleItalicComponent as RefAppFontStyleItalicComponent} from '@spi-ref-app-angular/app/font-style-italic/font-style-italic.component';

@Component({
  selector: 'app-font-style-italic',
  templateUrl: './font-style-italic.component.html',
  styleUrls: ['./font-style-italic.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class FontStyleItalicComponent extends RefAppFontStyleItalicComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
