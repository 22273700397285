import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {ParagraphSpaceComponent as RefAppParagraphSpaceComponent} from '@spi-ref-app-angular/app/paragraph-space/paragraph-space.component';

@Component({
  selector: 'app-paragraph-space',
  templateUrl: '../shared/templates/input-slider-base.component.html',
  styleUrls: ['./paragraph-space.component.scss']
})
export class ParagraphSpaceComponent extends RefAppParagraphSpaceComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
