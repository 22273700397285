import {Component, ElementRef, ViewEncapsulation} from '@angular/core';
import {Context} from '../../context/context';
import {FontFamilyFormComponent as RefAppFontFamilyFormComponent} from '@spi-ref-app-angular/app/font-family/font-family-form/font-family-form.component';

@Component({
  selector: 'app-font-family-form',
  templateUrl: '../font-family.component.html',
  styleUrls: ['./font-family-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FontFamilyFormComponent extends RefAppFontFamilyFormComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
