import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import { Context } from '../context/context';
import { InsetSpacingComponent as RefAppInsetSpacingComponent } from '@spi-ref-app-angular/app/inset-spacing/inset-spacing.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-inset-spacing',
  templateUrl: './inset-spacing.component.html',
  styleUrls: ['./inset-spacing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsetSpacingComponent extends RefAppInsetSpacingComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }
  
  protected getViewComponent():any{
    return new SpiSdk.InsetSpacingViewLPI();
  }

}
