import { Component, AfterViewInit } from '@angular/core';
import { onIframeMessage } from './app.storyCreator';

try {
  window.addEventListener('message', onIframeMessage, false);
} catch (e) {
  console.log(e.message);
}

declare var SpiSdk: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  public static LAYERS_TAB = 'Layers';
  public static ARTICLES_TAB = 'Articles';
  public static IMAGE_GALLERY_TAB = 'ImageGallery';

  title = 'app';
  public isDebugMode = false;
  public displayLayers = false;
  public enableBrandFolderGallery: boolean = SpiSdk.Model.config
                                              && SpiSdk.Model.config.brandfolderApiUrl
                                              && SpiSdk.Model.config.brandfolderApiUrl.length > 0;

  public damActive: boolean = SpiSdk.Model.config && SpiSdk.Model.config.damActive === '1';
  protected selectedPanelTab;

  constructor() {
  }

  ngAfterViewInit() {
    this.addEventListeners();
    this.selectedPanelTab = AppComponent.IMAGE_GALLERY_TAB;
    this.isDebugMode = SpiSdk.Model.config.isDebugMode === true || SpiSdk.Model.config.isDebugMode === 'true';
    this.displayLayers = SpiSdk.Model.config.displayLayers;
  }

  addEventListeners() {
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.GroupEvent.GROUP_UPDATED, this, 'onGroupUpdated');
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.GroupEvent.GROUP_ADDED, this, 'onGroupUpdated');
  }

  removeEventListeners() {
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.GroupEvent.GROUP_UPDATED, this, 'onGroupUpdated');
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.GroupEvent.GROUP_ADDED, this, 'onGroupUpdated');
  }

  onGroupUpdated(event) {
    // Do not switch to the panel before it is fully initialized
    if (SpiSdk.Model.groupsModel.currentGroupList !== null) {
      this.selectTab(AppComponent.ARTICLES_TAB);
    }
  }

  isTabSelected(tabName: string): boolean {
    return this.selectedPanelTab === tabName;
  }

  selectTab(tabName: string): void {
    this.selectedPanelTab = tabName;
  }

  updateDocViewZoom(): void {
    SpiSdk.EventDispatcher.dispatch(new SpiSdk.DocumentViewEvent(SpiSdk.DocumentViewEvent.DOC_VIEW_RESIZED));
  }

  ngOnDestroy(){
    this.removeEventListeners();
  }
}
