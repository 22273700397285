import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {RotateBtnComponent as RefAppRotateBtnComponent} from '@spi-ref-app-angular/app/rotate-btn/rotate-btn.component';

@Component({
  selector: 'app-rotate-btn',
  templateUrl: './rotate-btn.component.html',
  styleUrls: ['./rotate-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RotateBtnComponent extends RefAppRotateBtnComponent implements OnInit {

  constructor(context: Context, protected element: ElementRef) {
    super(context, element);
  }
}
