import { Component, ElementRef, AfterContentInit, AfterViewInit, ViewChildren, QueryList, ViewEncapsulation } from '@angular/core';
import { Context } from '../context/context';
import { MenuComponent as RefAppMenuComponent} from '@spi-ref-app-angular/app/menu/menu.component'
import { ModalService } from '../modal.service';

declare var SpiSdk: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent extends RefAppMenuComponent {
  constructor(context: Context, protected element: ElementRef, public modalService: ModalService) {
    super(context, element, modalService);
  }
}
