import {Component, OnInit, ViewEncapsulation, ElementRef} from '@angular/core';
import {Context} from '../context/context';
import {StyleColorSelectorComponent as RefAppStyleColorSelectorComponent} from '@spi-ref-app-angular/app/style-color-selector/style-color-selector.component';

declare var SpiSdk: any;

@Component({
  selector: 'app-style-color-selector',
  templateUrl: './style-color-selector.component.html',
  styleUrls: ['./style-color-selector.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class StyleColorSelectorComponent extends RefAppStyleColorSelectorComponent implements OnInit {

  constructor(context: Context, protected element:ElementRef) {
    super(context, element);
  }
}
