import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import {SpiImageSearchComponent as RefAppSpiImageSearchComponent} from '@spi-ref-app-angular/app/spi-image-search/spi-image-search.component';
import {Context} from "../context/context";

@Component({
  selector: 'app-spi-image-search',
  templateUrl: './spi-image-search.component.html',
  styleUrls: ['./spi-image-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SpiImageSearchComponent extends RefAppSpiImageSearchComponent {

  
  constructor(context:Context, element: ElementRef) {
    super(context, element);
  }

}
