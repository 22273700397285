import { Component, OnInit, ElementRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { AbstractContextBtnComponent } from '@spi-ref-app-angular/app/abstract-context-btn/abstract-context-btn.component';
import { Context } from '../../context/context';
import { ConfirmComponent } from '../../modal-component/confirm/confirm.component';

declare var SpiSdk: any;

@Component({
  selector: 'app-article-context-btn',
  templateUrl: './article-context-btn.component.html',
  styleUrls: ['./article-context-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArticleContextButtonComponent extends AbstractContextBtnComponent implements OnInit {

  @ViewChild('confirmRemove', {static: true})
  confirmRemove:ConfirmComponent;

  attachedToArticle = null;
  selectedPageItemId = null;
  contextMenuId = 'addToArticleModalContextMenu';

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

  protected addGroupEventListeners():void{
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.GroupEvent.GROUP_UPDATED, this, 'updateComponentState');
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.GroupEvent.GROUP_ADDED, this, 'updateComponentState');
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.GroupEvent.GROUP_REMOVED, this, 'updateComponentState');
  }

  protected removeGroupEventListeners():void{
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.GroupEvent.GROUP_UPDATED, this, 'updateComponentState');
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.GroupEvent.GROUP_ADDED, this, 'updateComponentState');
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.GroupEvent.GROUP_REMOVED, this, 'updateComponentState');
  }

  protected removeEventListeners():void{
    super.removeEventListeners();
    this.removeGroupEventListeners();
  }

  protected getSelectedControl():any {
    // We don't support article context buttons for multiselect
    return SpiSdk.SelectionManager.getSingleSelectedControl();
  }

  protected getSelectedPageItemId():string {
    const selectedControl = this.getSelectedControl();
    return (selectedControl ? selectedControl.pageItem.id : null);
  }

  protected onControlSelected():void{
    this.selectedPageItemId = this.getSelectedPageItemId();

    if (!this.selectedPageItemId) {
      this.removeGroupEventListeners();
    }
    else {
      this.addGroupEventListeners();
    }

    super.onControlSelected();
  }

  protected getPageItemArticleTitle(article, pageItemId) {
    const groupElement = article ? article.getGroupElementByPageItemId(pageItemId) : null;
    return (article ? `${article.name} - ${groupElement.displayName}` : null);
  }

  protected updateComponentState():void {
    super.updateComponentState();

    // Get article selected page item is attached at the moment
    const article = this.selectedPageItemId ? SpiSdk.Model.groupsModel.getGroupByPageItemId(this.selectedPageItemId) : null;
    this.attachedToArticle = article ? article : null;
    const articleInfo = this.getPageItemArticleTitle(article, this.selectedPageItemId);
    const title = articleInfo ? `Remove from ${articleInfo}` : 'Designate Article';
    this.updateAttachedToArticleState(this.attachedToArticle !== null, title);
  }

  protected updateAttachedToArticleState(attachedToArticle:boolean, title:string):void {
    if (!this.btnElem) {
      return;
    }
    const button = this.btnElem.nativeElement;
    const attachedToArticleClass = 'attached-to-article';

    button.classList.remove(attachedToArticleClass);
    if (attachedToArticle) {
      button.classList.add(attachedToArticleClass);
    }

    button.setAttribute('title', title);
  }

  protected showForSelectedControl (selectedControl):boolean {
    const pageItem = selectedControl ? selectedControl.pageItem : null;

    // Article context button is only shown for images and text
    return pageItem instanceof SpiSdk.ImagePageItem || pageItem instanceof SpiSdk.TextPageItem;
  }

  onBtnClick (event):void {
    event.stopImmediatePropagation();
    event.stopPropagation();

    // If page item is not attached to any articles, we show context pop up of article options
    if (!this.attachedToArticle) {
      this.showContextMenu(event);
    }
    // If page item is a part of an article, clicking the button triggers removing
    else {
      this.confirmRemove.confirm(null, `Remove selection from ${this.getPageItemArticleTitle(this.attachedToArticle, this.selectedPageItemId)}?`);
    }
  }

  removeFromArticle() {
    const {groupsModel} = SpiSdk.Model;
    // Store the ref to the article, this.attachedToArticle will be reset after we remove selected page item from it
    const article = this.attachedToArticle;
    groupsModel.removePageItemFromGroup(this.selectedPageItemId, article);
  }

  protected showContextMenu(event) {
    const {clientX, clientY} = event;
    SpiSdk.EventDispatcher.dispatch(new SpiSdk.DocumentViewEvent(
      SpiSdk.DocumentViewEvent.SHOW_CONTEXT_MENU,
      {contextMenuId: this.contextMenuId, pageItemId: this.selectedPageItemId, clientX, clientY})
    );
  }

}
