import {Component, ViewEncapsulation, ElementRef} from '@angular/core';
import {Context} from '../context/context';
import {FontFamilyComponent as RefAppFontFamilyComponent} from '@spi-ref-app-angular/app/font-family/font-family.component';

@Component({
  selector: 'app-font-family',
  templateUrl: './font-family.component.html',
  styleUrls: ['./font-family.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FontFamilyComponent extends RefAppFontFamilyComponent {
  constructor(context: Context, protected element: ElementRef) {
    super(context, element);
  }
}
