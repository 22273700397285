import {Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input} from '@angular/core';
import {Context} from '../context/context';
import {ColumnsComponent as RefAppColumnsComponent} from '@spi-ref-app-angular/app/columns/columns.component';

declare var SpiSdk: any;
declare var FriesensColumnsView: any;

@Component({
  selector: 'app-columns',
  templateUrl: './columns.component.html',
  styleUrls: ['./columns.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class ColumnsComponent extends RefAppColumnsComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
