import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {DocumentViewComponent as RefAppDocumentViewComponent} from '@spi-ref-app-angular/app/document-view/document-view.component';
import { ToastrService } from 'ngx-toastr';

declare var SpiSdk: any;

@Component({
  selector: 'spidocview',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentViewComponent extends RefAppDocumentViewComponent {

  constructor(context:Context, private toastrService:ToastrService) {
    super(context);
  }

  protected addEventListeners() {
    super.addEventListeners();
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.LayoutEvent.GOT_LAYOUT, this, 'onLayoutLoaded');
  }

  protected removeEventListeners() {
    super.removeEventListeners();
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.LayoutEvent.GOT_LAYOUT, this, 'onLayoutLoaded');
  }

  protected onInstanceReady (event) {
    super.onInstanceReady(event);
    // Layout load is not triggered on the first page
    this.checkPageWarnings(1);
  }

  protected onLayoutLoaded(event) {
    const {layoutNum} = event.payload;
    this.checkPageWarnings(layoutNum + 1);
  }

  protected checkPageWarnings (pageNumber:number) {
    const {currentInstance} = SpiSdk.Model.sessionModel;
    const {skippedPageItemsByPageNumber} = currentInstance;
    const warnings = skippedPageItemsByPageNumber ? skippedPageItemsByPageNumber[pageNumber] : null;
    if (warnings && warnings.length) {
      const message = warnings.map(({pageItemId, reason}) => `${pageItemId}: ${reason}`).join('<br/>');
      console.log(message);
      this.toastrService.warning(message, 'SKIPPED the following page items:', {closeButton: true, disableTimeOut: true, enableHtml: true});
    }
  }
}
