const BRANDFOLDER_API_URL = `https://lpi.dev.storycreator.io/api/v1/integration`;
const PRINT_UI_EDITOR_URL = `https://wecreate.4lpi.com`;
const DOC_VIEWER_URL = `https://wecreate.4lpi.com/viewer/lpi/`;

export const BASIC_PARAMS = {
  userAccessToken: '',
  templateId: null,
  instanceId: null,
  margins: '{"top": 20, "bottom": 20, "outside": 20, "inside": 20}',
  showMargins: true,
  templateDisplayName: 'Test template',
  daoCode: 'nucleus',
  parserCode: 'nucleus',
  httpServiceCode: 'nucleus',
  eventDispatcherCode: 'abstract',
  useLocalHostProxy: false,
  cacheServiceResults: true,
  lazyLoadLayouts: true,
  displayLayers: false,
  imageGallerySettingsBySource: {'nucleus': {searchIn: 'alias,filename,virtual_path', allowUpload: true, userUploadCategory: 'User Upload'}},
  defaultText: 'Enter your text here',//global text to use of renderDefaultText is true but defaultText is undefined
  galleryItemsPerPage: 9,
  zoomIncrement: .25,
  useTouchZoomForMobile: true,
  layoutsPerPage: 2,
  pageNavThumnailsPerPage: 2,
  fontLoadingTimeout: 7000,
  newTextParams: '{"width": "20%", "height": "10%", "rotation" : 0, "top" : "center", "left" : "center", ' +
    '"defaultText" : "", "verticalAlign" : "top", "textAlign" : "left", "copyfitting" : "none"}',
  newImageParams: '{"width": "30%", "height": "30%", "rotation" : 0, "top" : "center", "left" : "center", ' +
    '"scaleType": "fitFrameProportionally"}',
  useLocalFonts: false, // no app should use local fonts, but can if the client absolutely needs it
  selectableControlClass: 'defaultTextBorder', // Controls the dotted frame outline of text controls that are editable, including those with default text
  // List of profanity words separated by comma.
  profanityFilter: '',
  // Error messages for image validation
  // Under DPI error
  isOverMinPpi: '__IMAGE_NAME__ is below the recommended image resolution. To guarantee the best print quality, upload an image at 300 DPI.',
  // Placeholder content error
  placeHolderError: 'Add your message for placeholder error here',
  marginError: 'The page item is outside the page margins',
  // Error messages for text validation
  // Overset text error
  isOverSet: 'Text box has hidden text',
  incorrectStructureError: 'Incorrect structure encountered. Please use the undo button to undo your edits.',
  // Required text error
  textRequired: 'Text is required in this field.',
  textContainsProfanity: 'Our profanity filter requires that you revise your text.',
  // Error messages for service actions such as create instance, create session, and update layout
  serviceActionError: 'Add your error message for service actions here',
  // Pages non loaded error
  pageNonLoaded: 'Add your message for page not loaded here',
  // this controls whether or not validation errors result in the user having the option to continue with errors or not
  allowContinueWithError: false,
  copyfitAndCorrectOnTheFly: true, //  Shows if text should be copyfit immediately after the text is changed
  // Delay in ms that correction algorithm would wait after text edit event before starting text checks and corrections.
  // If equal to 0, text checks will be done after each text edit event. Increasing this parameter improves performance,
  // but makes user visual experience worse since text will be corrected with delay.
  textCorrectionsDelay: 0,
  // prevent copyfit fail anyway even if proportion is changed
  preventCopyfitFail: true,
  /*variableData: {
    spi_vars: [
      {name: 'document_customerstate', value: 'CC'},
      {name: 'document_customercity', value: 'My City'}
    ]
  },*/
  outputType: 0,
  fontSizeOptions: '5,6,7,8,9,10,11,12,14,16,18,21,24,28,32,36,42,48,60,72,96',
  lineHeightOptions: '5,6,7,8,9,10,11,12,14,16,18,21,24,28,32,36,42,48,60,72,96',
  minRecommendedFontSize: 5,
  fontCaseOptions: [
    {value: 'default', label: 'Default'},
    {value: 'uppercase', label: 'Uppercase'},
    {value: 'smallCaps', label: 'Small Caps'}
  ],
  bulletListStyleTypes: [
    {value: 'disc', label: 'Disc', unicode: '\\2022'},
    {value: 'circle', label: 'Circle', unicode: '\\25CB'},
    {value: 'square', label: 'Square', unicode: '\\25AA'},
  ],
  numberedListStyleTypes: [
    {value: 'decimal', label: 'Decimal'},
    {value: 'decimal-leading-zero', label: 'Decimal Leading Zero'},
    {value: 'upper-alpha', label: 'Upper Alpha'},
    {value: 'lower-alpha', label: 'Lower Alpha'},
    {value: 'upper-roman', label: 'Upper Roman'},
    {value: 'lower-roman', label: 'Lower Roman'},
  ],
  // At the moment we only use equal values for textIndent and paragraphStartIndent, if they are different,
  // paragraphStartIndent will be used
  defaultListIndents: {
    textIndent: 1,
    paragraphStartIndent: 1
  },
  // Coefficient to multiply paragraphStartIndent in case of numbering lists
  numberListIndentsCoeff: 1.4,
  // Coefficient to multiply paragraphStartIndent in case of wide numbering lists (zero leading, roman-upper)
  wideNumberListIndentsCoeff: 1.8,
  newPathParams: '{"stroke": "#000000", "strokeSwatchName": "Black", "fillColor": "#FFFFFF", "fillSwatchName": "Paper", "strokeWeight": 1, "fillOpacity": 100, "strokeOpacity": 100, "scaleProportionally": false}',
  shapesPlaceholderColor: '#0000ff',
  alwaysUseImageWithEditableFrame: true,
  selectionManager: 'multiSelect',
  minEffectivePPI: 300,
  units: 'point',
  useFilterFonts: false,
  maxArticlesPerNewsletter: 10,
  maxEventsPerNewsletter: 3,
  useAtomicUpdate: true,
  allowCopyBetweenInstances: true,
  backendAdminPath: '/lpi',
  backendAdminUrl: null,
  hyperlinkColor: {rgb: '#0645AD', cmyk: '60|51|01|00'},
  // Other possible options: fontId, fontSize, fontWeight, fontStyle.
  // Do not include color, hyperlinkColor will be used.
  hyperlinkParams: {textDecoration: 'underline'},
  initiateAssetCleanup: true,
  replaceMissingAssetsWithPlaceholder: true,
  docViewerUrl: DOC_VIEWER_URL,
  printUiEditorUrl: PRINT_UI_EDITOR_URL,
  brandfolderApiUrl: BRANDFOLDER_API_URL,
};
