import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {AddTextFrameBtnComponent as RefAppAddTextFrameBtnComponent} from '@spi-ref-app-angular/app/add-text-frame-btn/add-text-frame-btn.component';

@Component({
  selector: 'app-add-text-frame-btn',
  templateUrl: './add-text-frame-btn.component.html',
  styleUrls: ['./add-text-frame-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddTextFrameBtnComponent extends RefAppAddTextFrameBtnComponent implements OnInit {
  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }
}
