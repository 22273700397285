import * as queryString from 'querystring';
import {IConfig, IEventDispatcher, IDesignerService, IServiceResultParser, IServiceExporter,  IModel, ISelectionManager} from '@silpub/spi-core';
import {IBaseCommandMap} from '@silpub/spi-refapp';
import {Injector, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ContextNucleus as RefAppContextNucleus} from '@spi-ref-app-angular/app/context/context-nucleus';
import debounce from 'debounce';

declare var SpiSdk: any;
declare var SpiRef: any;
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class Context extends RefAppContextNucleus {

  public onSaveTriggerDebounce = debounce(this.onSaveTrigger, 500);
  public saved = true;

  protected binder = new SpiSdk.Binder();

  constructor () {
    super();
  }

  protected getConfig(): IConfig {
    return new SpiSdk.LpiConfig() as IConfig;
  }

  // This method must be overriden to return correct project environment
  protected getEnv():any {
    return environment;
  }

  protected getCommandMap():IBaseCommandMap {
    return SpiRef.LPICommandMap.newInstance() as IBaseCommandMap;
  }

  protected getURLValue(key, defaultValue) {
    return SpiSdk.URLUtils.getQuerystring(window.location.href, key, defaultValue, true);
  }

  protected updateConfigFromEnv() {
    super.updateConfigFromEnv();
    this.config.debugMode = Boolean(this.getURLValue('debug', false));
  }

  protected addEventListeners():void{
    super.addEventListeners();
    // catch instance loaded event dispatched when instance data are loaded and about to be parsed
    this.eventDispatcher.addEventListener(SpiSdk.InstanceEvent.INSTANCE_LOADED, this, 'onInstanceLoaded');
  }

  protected onInstanceLoaded ():void {
    this.eventDispatcher.removeEventListener(SpiSdk.InstanceEvent.INSTANCE_LOADED, this, 'onInstanceLoaded');
    // Bind to currentInstance.swatches update swatches once they are parsed but other elements (depending on them)
    // are still not.
    this.binder.bind(SpiSdk.Model.sessionModel.currentInstance, 'swatches', this, "onSwatchesParsed");
  }

  protected onSwatchesParsed ():void {
    this.binder.unbindAll();

    const customColorPalette = this.getCustomColorPalette();
    const instanceSwatches = SpiSdk.Model.sessionModel.currentInstance.swatches;

    // Sometimes custom palette is updated by user, but previous color values are already saved into the SDXML. In this
    // case we have to update instance swatches with actual custom palette values. Otherwise it will cause issues at SDXML
    // export. This should happen before (!) stories are parsed since they use swatches to assign rgb values.
    instanceSwatches.source().forEach(instanceSwatch => {
      const {templateName, rgb} = instanceSwatch;
      const colorPaletteSwatch = customColorPalette.swatchesByTemplateName[templateName];
      if (colorPaletteSwatch && colorPaletteSwatch.rgb !== rgb) {
         instanceSwatches.replace(instanceSwatch, colorPaletteSwatch);
      }
    });
  }

  // Will come back once we have vent sourcing and optimistic updating in place
  public onInstanceReady(event:any):void{
    super.onInstanceReady(event);
    this.initCustomStyles();

    this.eventDispatcher.addEventListener(SpiSdk.UndoRedoEvent.ADD_MEMENTO, this, 'onSaveTriggerDebounce');
    this.eventDispatcher.addEventListener(SpiSdk.UndoRedoEvent.UNDO, this, 'onSaveTriggerDebounce');
    this.eventDispatcher.addEventListener(SpiSdk.UndoRedoEvent.REDO, this, 'onSaveTriggerDebounce');

    // Temporary solution
    this.eventDispatcher.addEventListener(SpiSdk.InstanceEvent.INSTANCE_SAVED, this, 'onSaveCallback');

    const self = this;

    /** Remove this as ePub handles beforeunload prompt by itself */
    // window.addEventListener('beforeunload', (e) => {
    //   if (!self.saved) {
    //     e.preventDefault();
    //     e.returnValue = '';
    //   }
    // });

    this.initMargins();
  }

  protected setUpGlobals() {
    super.setUpGlobals();
    // Custom groups model that contains LPI articles
    SpiSdk.Model.groupsModel = new SpiSdk.ArticlesModel();
    // Specific for LPI hyperlinks model that contains data about hyperlinks on each page
    SpiSdk.Model.hyperlinksModel = new SpiSdk.HyperlinksModel();
  }

  protected initMargins() {
    // the customer asked margins default to on with the sizes based on product type
    const { layoutType, instanceRecord } = SpiSdk.Model.sessionModel.currentInstance;
    if (instanceRecord.parameters && instanceRecord.parameters.margins) {
        return; // Margins were loaded from variables so we can skip defaults
    }
    // TODO: review and refactor
    const margin = this.getMarginByLayoutType(layoutType);
    const marginsSettings = {};
    ["top", "bottom", "outside", "inside"].forEach(side => {
      SpiSdk.DocViewUtils.getMarkupModel().margins[side] = margin;
      marginsSettings[side] = margin;
    });
    SpiSdk.Model.config.margins = JSON.stringify(marginsSettings);
  }

  protected getMarginByLayoutType(layoutType):number {
    // the customer asked margins default to on with the sizes based on product type
    switch (layoutType) {
      case 'Bulletin':
      case 'Bulletins':
      case 'Newsletter':
      case 'Newsletters':
        return 26;
      case 'Directory':
      case 'Directories':
        return 35
      case 'Flyer':
      case 'Flyers':
        return 17;
    }

    return 17;
  }

  protected getCMYKArr(cmyk):Array<number> {
    if (!cmyk || !cmyk.length) {
      return [];
    }

    return cmyk.split(',').map(item => parseInt(item));
  }

  protected validateColor(colorData) {
    const {rgb, cmyk} = colorData;
    if (!rgb || !rgb.length || !/^#[0-9A-F]{6}$/i.test(rgb)) {
      console.log(`ERROR: RBG value = ${rgb} is incorrect for the color ${JSON.stringify(colorData)}`);
      return false;
    }

    const cmykArr = this.getCMYKArr(cmyk);
    if (cmykArr.length !== 4
        || cmykArr.filter(cmykPart => isNaN(cmykPart) || cmykPart < 0 || cmykPart > 100).length > 0) {
      console.log(`ERROR: CMYK value = ${cmyk} is incorrect for the color ${JSON.stringify(colorData)}`);
      return false;
    }

    return true;
  }

  protected getSwatch(colorData) {
    if (!this.validateColor(colorData)) {
      return null;
    }

    const {rgb, cmyk, display} = colorData;
    let swatch = new SpiSdk.Swatch();
    swatch.rgb = rgb;
    swatch.cmykValue = this.getCMYKArr(cmyk).map(item => item.toString(16)).join('|');
    swatch.designerLabel = SpiSdk.ColorUtils.getSwatchName(swatch);
    swatch.templateName = swatch.designerLabel;
    swatch.model = 'PROCESS';
    return swatch;
  }

  protected getColorPalette(paletteVariableName) {
    const {variablesByName} = SpiSdk.Model.config.appVariables;
    const colorPaletteVariable = variablesByName[paletteVariableName];
    if (colorPaletteVariable && colorPaletteVariable.value) {
      const colors = colorPaletteVariable.value;
      if (colors && colors.length > 0) {
        return colors.map ((colorData => this.getSwatch(colorData)).bind(this)).filter(swatch => swatch !== null);
      }
    }

    return [];
  }

  protected getCustomColorPalette() {
    const userColorPalette = this.getColorPalette('color_palette');
    const globalColorPalette = this.getColorPalette('color_palette_global');

    let colorPalette = new SpiSdk.SwatchList();

    userColorPalette.forEach(swatch => {
      colorPalette.addItem(swatch);
    });

    globalColorPalette.forEach(swatch => {
      colorPalette.addItem(swatch);
    });

    return colorPalette;
  }

  protected initColorModel() {
    const colorPalette = this.getCustomColorPalette();

    const instanceSwatches = SpiSdk.Model.sessionModel.currentInstance.swatches;

    instanceSwatches.source().forEach(instanceSwatch => {
      colorPalette.addItem(instanceSwatch);
    });

    SpiSdk.Model.colorModel.colorPalette = colorPalette;
  };

  protected getTextEditSelector():string {
    return '.text-edit-panel';
  }

  private parseCustomStyles(result) {
    const $stylesXML = $($.parseXML(result));
    const parser = new SpiSdk.CustomStyleParser();
    if (!parser.canParse($stylesXML)) {
      throw new Error(`can not parse styles from the provided XML: \n\r${result}`);
    }
    return parser.parse($stylesXML,
      SpiSdk.Model.sessionModel.currentInstance.fonts,
      SpiSdk.Model.colorModel.colorPalette);
  }

  protected getCustomStyles() {
    const {appVariables, userAccessToken} = SpiSdk.Model.config;
    const isTokenProtected = userAccessToken !== null && userAccessToken !== undefined && userAccessToken.length > 0;
    const styles = appVariables.aList.find(variable => {
      return variable.name === 'styles'
              && isTokenProtected === variable.isAccessToken;
    });
    if (!styles) {
      // default to empty paragraph style list
      return new SpiSdk.StyleList();
    }

    return this.parseCustomStyles(styles.value);
  };

  protected initCustomStyles() {
    const customStyles = this.getCustomStyles();
    SpiSdk.Model.config.customStyles = customStyles;
    customStyles.aList.forEach((style: {name: string, swatchName:string}) => {
      // Custom styles swatches are added to the SpiSdk.Model.colorModel.colorPalette
      // during the parsing, so we can use it to look up the custom style swatch.
      const styleSwatch = SpiSdk.Model.colorModel.colorPalette.swatchesByTemplateName[style.swatchName];
      SpiSdk.CustomStyleUtils.registerCustomStyle(style, styleSwatch);
    });
  }

  protected onSaveTrigger() {
    //this.eventDispatcher.addEventListener(SpiSdk.InstanceEvent.INSTANCE_SAVED, this, 'onSaveCallback');
    //SpiSdk.Model.asyncOperationModel.disable = true;
    //SpiSdk.SaveBtn.prototype.onBtnClick.call(this);
    this.saved = false;
  }

  protected async onSaveCallback() {
    //this.eventDispatcher.removeEventListener(SpiSdk.InstanceEvent.INSTANCE_SAVED, this, 'onSaveCallback');
    //SpiSdk.Model.asyncOperationModel.disable = false;
    // wait for styles to update in Nucleus before proceeding
    await this.service.updateCustomStyles();
    this.saved = true;
  }
}
