import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {CoordinatesAndSizeComponent as RefAppCoordinatesAndSizeComponent} from '@spi-ref-app-angular/app/coordinates-and-size/coordinates-and-size.component';

@Component({
  selector: 'app-coordinates-and-size',
  templateUrl: './coordinates-and-size.component.html',
  styleUrls: ['./coordinates-and-size.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CoordinatesAndSizeComponent extends RefAppCoordinatesAndSizeComponent {

  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }

}
