import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {StrokeToggleComponent as RefAppStrokeToggleComponent} from '@spi-ref-app-angular/app/stroke-toggle/stroke-toggle.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-stroke-toggle',
  templateUrl: './stroke-toggle.component.html',
  styleUrls: ['./stroke-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StrokeToggleComponent extends RefAppStrokeToggleComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
