import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {ExitBtnComponent as RefAppExitBtnComponent} from '@spi-ref-app-angular/app/exit-btn/exit-btn.component';

@Component({
  selector: 'app-exit-btn',
  templateUrl: './exit-btn.component.html',
  styleUrls: ['./exit-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExitBtnComponent extends RefAppExitBtnComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
