import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {RulerComponent as RefAppRulerComponent} from '@spi-ref-app-angular/app/ruler/ruler.component';

declare var SpiSdk: any;
declare var SpiRef: any;
declare var $: any;

@Component({
  selector: 'app-ruler',
  templateUrl: './ruler.component.html',
  styleUrls: ['./ruler.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class RulerComponent extends RefAppRulerComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
