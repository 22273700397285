import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import { ConfirmComponent } from '../modal-component/confirm/confirm.component';
import {GroupsComponent as RefAppGroupsComponent} from '@spi-ref-app-angular/app/groups/groups.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GroupsComponent extends RefAppGroupsComponent implements OnInit {

  @ViewChild('confirmDeleteArticle', {static: true})
  confirmDeleteArticle:ConfirmComponent;

  @ViewChild('title', {static: true})
  title:ElementRef;

  protected articleIdToDelete = null;

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

  protected init():void {
    super.init();
    this.setupBindings();
  }

  protected addEventListeners(): void {
    super.addEventListeners();
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.GroupEvent.CONFIRM_DELETE_GROUP, this, 'onConfirmDeleteGroup');
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.GroupEvent.GROUP_UPDATED, this, 'onGroupUpdated');
  }

  protected removeEventListeners(): void {
    super.removeEventListeners();
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.GroupEvent.CONFIRM_DELETE_GROUP, this, 'onConfirmDeleteGroup');
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.GroupEvent.GROUP_UPDATED, this, 'onGroupUpdated');
  }

  protected onConfirmDeleteGroup(event): void {
    const {groupId} = event.payload;

    const {groupsModel} = SpiSdk.Model;
    const group = groupsModel.getGroupById(groupId);

    if(!group){
        throw new Error(`Article ${groupId} is not found`);
    }

    this.articleIdToDelete = groupId;
    const msg = `Are you sure you want to remove ${group.name}?`;
    this.confirmDeleteArticle.confirm(null, msg);
  }

  protected deleteArticle(): void {
    if (this.articleIdToDelete) {
      const payload = {groupId: this.articleIdToDelete, confirmed: true}
      SpiSdk.EventDispatcher.dispatch(new SpiSdk.GroupEvent(SpiSdk.GroupEvent.DELETE_GROUP, payload));
    }

    this.resetArticleToDelete();
  }

  protected rejectDeleteArticle(): void {
    this.resetArticleToDelete();
  }

  protected resetArticleToDelete() {
    this.articleIdToDelete = null;
  }

  protected setupBindings(): void {
    this.binder.bind(SpiSdk.Model.documentViewModel, 'selectedPage', this, 'onSelectedPageChanged');
    this.binder.bind(SpiSdk.Model.groupsModel, 'currentGroupList', this, 'onCurrentGroupListChanged');
  }

  protected onSelectedPageChanged(selectedPage): void {
    this.title.nativeElement.textContent = selectedPage ? `Page ${selectedPage.number} Articles` : '';
  }

  protected onCurrentGroupListChanged() {
    const {groupsModel} = SpiSdk.Model;
    if (!groupsModel.currentGroupList) {
      return;
    }

    const emptyArticles = groupsModel.currentGroupList.source().filter(article => article.isEmpty());
    emptyArticles.forEach(article => groupsModel.removeGroupFromCurrentList(article));
  }

  protected onGroupUpdated(event) {
    const {groupId} = event.payload;
    const {groupsModel} = SpiSdk.Model;
    const group = groupsModel.getGroupById(groupId);
    if (!group) {
      return;
    }

    // If group became empty, remove it from the model
    if (group.isEmpty()) {
      groupsModel.removeGroupFromCurrentList(group);
    }
  }
}
