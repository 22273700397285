import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {ContinueBtnComponent as RefAppContinueBtnComponent} from '@spi-ref-app-angular/app/continue-btn/continue-btn.component';

@Component({
  selector: 'app-continue-btn',
  templateUrl: './continue-btn.component.html',
  styleUrls: ['./continue-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContinueBtnComponent extends RefAppContinueBtnComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
