import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {ZoomBtnComponent as RefAppZoomBtnComponent} from '@spi-ref-app-angular/app/zoom/zoom-btn.component';

declare var SpiSdk: any;
declare var SpiRef: any;
declare var $: any;

@Component({
  selector: 'app-zoom-btn',
  templateUrl: './zoom-btn.component.html',
  styleUrls: ['./zoom-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZoomBtnComponent extends RefAppZoomBtnComponent {
  constructor(context:Context) {
    super(context);
  }
}
