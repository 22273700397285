import {Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {RotationPanelComponent as RefAppRotationPanelComponent} from '@spi-ref-app-angular/app/rotation-panel/rotation-panel.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-rotation-panel',
  templateUrl: './rotation-panel.component.html',
  styleUrls: ['./rotation-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RotationPanelComponent extends RefAppRotationPanelComponent implements OnInit {
}
