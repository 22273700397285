import { Component, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import {Context} from '../context/context';
import {FillColorFillComponent as RefAppFillColorFillComponent} from '@spi-ref-app-angular/app/fill-color-fill/fill-color-fill.component';

@Component({
  selector: 'app-fill-color-fill',
  templateUrl: './color-fill.component.html',
  styleUrls: ['./color-fill.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FillColorFillComponent extends RefAppFillColorFillComponent implements OnInit {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }
}
