import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HyperlinkPanelComponent as RefAppHyperlinkPanelComponent } from '@spi-ref-app-angular/app/hyperlink-panel/hyperlink-panel.component';

@Component({
  selector: 'app-hyperlink-panel',
  templateUrl: './hyperlink-panel.component.html',
  styleUrls: ['./hyperlink-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HyperlinkPanelComponent extends RefAppHyperlinkPanelComponent implements OnInit {  

}
