import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {PageNavigationComponent as RefAppPageNavigationComponent} from '@spi-ref-app-angular/app/page-navigation/page-navigation.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-page-navigation',
  templateUrl: './page-navigation.component.html',
  styleUrls: ['./page-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageNavigationComponent extends RefAppPageNavigationComponent {

  constructor(context:Context) {
    super(context);
  }

}
