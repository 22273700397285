import {Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {MultiSelectPanelComponent as RefAppMultiSelectPanelComponent} from '@spi-ref-app-angular/app/multi-select-panel/multi-select-panel.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-multi-select-panel',
  templateUrl: './multi-select-panel.component.html',
  styleUrls: ['./multi-select-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MultiSelectPanelComponent extends RefAppMultiSelectPanelComponent {
}
