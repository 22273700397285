import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {FileUploadBtnComponent as RefAppFileUploadBtnComponent} from '@spi-ref-app-angular/app/file-upload-btn/file-upload-btn.component';

declare var SpiSdk: any;
declare var SpiRef: any;
declare var $: any;

@Component({
  selector: 'app-file-upload-btn',
  templateUrl: './file-upload-btn.component.html',
  styleUrls: ['./file-upload-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FileUploadBtnComponent extends RefAppFileUploadBtnComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }
}
