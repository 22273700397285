import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {ShadowPositionComponent as RefAppShadowPositionComponent} from '@spi-ref-app-angular/app/shadow-position/shadow-position.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-shadow-position',
  templateUrl: './shadow-position.component.html',
  styleUrls: ['./shadow-position.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShadowPositionComponent extends RefAppShadowPositionComponent {

  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }

}
