import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {StyleSelect as RefAppStyleSelect} from '@spi-ref-app-angular/app/style-select/style-select.component';

//dereference the SDK. This ensure we don't get duplicate SDK code in the main bundle
declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-style-select',
  templateUrl: './style-select.component.html',
  styleUrls: ['./style-select.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class StyleSelectComponent extends RefAppStyleSelect implements OnInit {

  constructor(context:Context) {
    super(context);
  }

}
