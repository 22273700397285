import {Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {StrokePanelComponent as RefAppStrokePanelComponent} from '@spi-ref-app-angular/app/stroke-panel/stroke-panel.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-stroke-panel',
  templateUrl: './stroke-panel.component.html',
  styleUrls: ['./stroke-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class StrokePanelComponent extends RefAppStrokePanelComponent {
}
