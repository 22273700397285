import {Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input} from '@angular/core';
import {Context} from '../context/context';
import {ValidationComponent as RefAppValidationComponent} from '@spi-ref-app-angular/app/validation/validation';
import {Geometry} from '@silpub/spi-core';

declare var SpiSdk: any;
declare var SpiRef: any;
declare var $: any;

@Component({
  selector: 'app-validation',
  templateUrl: './validation.html',
  styleUrls: ['./validation.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidationComponent extends RefAppValidationComponent {
  constructor(context: Context, protected element: ElementRef) {
    super(context, element);
  }

  protected addValidator (source) {
    super.addValidator(source);
    // add a movement validator. This validator validates against margins
    if (source.pageItem) {
      const constraint = new SpiSdk.MarginsMovementValidator();
      constraint.source = source;
      constraint.sourceId = source.pageItem.id;
      SpiSdk.Model.validationModel.addValidator(constraint);
    }
  }
}
