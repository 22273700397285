import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {AbstractComponent} from '@spi-ref-app-angular/app/abstract-component/abstract-component';

declare var SpiSdk:any;

@Component({
  selector: 'app-save-status',
  templateUrl: './save-status.component.html',
  styleUrls: ['./save-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SaveStatusComponent extends AbstractComponent implements OnInit {

  @ViewChild('status', { static: true })
  statusLabel;

  constructor(context:Context, protected element:ElementRef) {
    super(context);
  }

  //Saving...
  //Saved
  ngAfterViewInit() {
    this.statusLabel.nativeElement.textContent = '';
  }

  protected addEventListeners(): void {
    super.addEventListeners();
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.UndoRedoEvent.ADD_MEMENTO, this, 'onSaveTrigger');
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.UndoRedoEvent.UNDO, this, 'onSaveTrigger');
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.UndoRedoEvent.REDO, this, 'onSaveTrigger');
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.InstanceEvent.INSTANCE_SAVED, this, 'onSaveCallback');
  }

  protected removeEventListeners(): void {
    super.removeEventListeners();
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.UndoRedoEvent.ADD_MEMENTO, this, 'onSaveTrigger');
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.UndoRedoEvent.UNDO, this, 'onSaveTrigger');
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.UndoRedoEvent.REDO, this, 'onSaveTrigger');
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.InstanceEvent.INSTANCE_SAVED, this, 'onSaveCallback');
  }

  protected onSaveTrigger() {
    this.statusLabel.nativeElement.textContent = 'Not Saved';
  }

  protected onSaveCallback() {
    this.statusLabel.nativeElement.textContent = '';
  }
}
