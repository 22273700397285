import {Component, OnInit, ViewEncapsulation, ViewChild, ElementRef} from '@angular/core';
import {Context} from '../context/context';
import {Swatch} from '@silpub/spi-core';
import {StyleAddComponent as RefAppStyleAddComponent} from '@spi-ref-app-angular/app/style-add/style-add.component';
import {FontSizeSliderFormComponent} from '../font-size-slider/font-size-slider-form/font-size-slider-form.component';
import {FontLineHeightSliderFormComponent} from '../font-line-height-slider/font-line-height-slider-form/font-line-height-slider-form.component';
import {StyleColorSelectorComponent} from "../style-color-selector/style-color-selector.component";
import {AbstractComponent} from "@spi-ref-app-angular/app/abstract-component/abstract-component";
import {FontFamilyFormComponent} from "../font-family/font-family-form/font-family-form.component";
import {ParagraphSpaceFormComponent} from "../paragraph-space/paragraph-space-form/paragraph-space-form.component";

// dereference the SDK. This ensure we don't get duplicate SDK code in the main bundle
declare var SpiSdk: any;

@Component({
  selector: 'app-style-add',
  templateUrl: './style-add.component.html',
  styleUrls: ['./style-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StyleAddComponent extends RefAppStyleAddComponent implements OnInit {
  @ViewChild('fontSizeSlider', {static: true})
  fontSizeSlider: FontSizeSliderFormComponent;
  @ViewChild('lineHeightSlider', {static: true})
  lineHeightSlider: FontLineHeightSliderFormComponent;
  @ViewChild('fontColor', {static: true})
  fontColor: StyleColorSelectorComponent;
  @ViewChild('fontSelector', {static: true})
  fontSelector: FontFamilyFormComponent;
  @ViewChild('alignLeft', {static: true})
  alignLeft: ElementRef;
  @ViewChild('alignCenter', {static: true})
  alignCenter: ElementRef;
  @ViewChild('alignRight', {static: true})
  alignRight: ElementRef;
  @ViewChild('boldCbx', {static: true})
  boldCbx: ElementRef;
  @ViewChild('italicCbx', {static: true})
  italicCbx: ElementRef;
  @ViewChild('spaceBeforeSlider', {static: true})
  spaceBeforeInput: ParagraphSpaceFormComponent;
  @ViewChild('spaceAfterSlider', {static: true})
  spaceAfterInput: ParagraphSpaceFormComponent;

  public newStyle:boolean = true;

  constructor(context: Context) {
    super(context);
  }

  protected emulateChangeEvent(component, value) {
    if (!component || !component.hostElement) {
      return;
    }

    var event = new CustomEvent('change', {detail: {value}});
    component.hostElement.dispatchEvent(event);
  }

  protected initSizeOptions() {
    // Inited by font size component
  }

  protected initFontOptions() {
    // Inited by font component
  }

  public onFontChange(payload: { fontId:string }) {
    this.emulateChangeEvent(this.fontSelector, payload.fontId);
  }

  public onFontSizeChange(payload: { size: number }) {
    this.emulateChangeEvent(this.fontSizeSlider, payload.size);
  }

  public onLineHeightChange(payload: { lineHeight: number }) {
    this.emulateChangeEvent(this.lineHeightSlider, payload.lineHeight);
  }

  public onSpaceBeforeChange(payload: { space: number }) {
    this.emulateChangeEvent(this.spaceBeforeInput, payload.space);
  }

  public onSpaceAfterChange(payload: { space: number }) {
    this.emulateChangeEvent(this.spaceAfterInput, payload.space);
  }

  public onSwatchChange(payload: { swatch:Swatch }) {
    this.emulateChangeEvent(this.fontColor, payload.swatch);
  }

  public show() {
    if (this.component) {
      this.component.show();
    }
  }

  protected getDefaultStyleParams () {
    return {
      fontSize: 12,
      lineHeight: 14,
      font: {fontId: ''},
      swatchName: 'Black',
      textAlign: 'left',
      paragraphSpaceBefore: 0,
      paragraphSpaceAfter: 0
    };
  }

  protected selectSwatch(swatchName) {
    const {colorPalette} = SpiSdk.Model.colorModel;
    const swatch = colorPalette.swatchesByTemplateName[swatchName];
    this.component.selectedSwatch = swatch;
    this.fontColor.setValue(swatch ? swatch.rgb : '');
  }

  protected selectFont(font) {
    this.fontSelector.setValue(font);
    // this is a hack as the core component doesn't support a slider. It requires a select list.
    this.emulateChangeEvent(this.fontSelector, font ? font.fontId : '');
  }

  protected selectFontSize(fontSize) {
    this.fontSizeSlider.setValue(fontSize);
    // this is a hack as the core component doesn't support a slider. It requires a select list.
    this.emulateChangeEvent(this.fontSizeSlider, fontSize);
  }

  protected selectLineHeight(lineHeight) {
    this.lineHeightSlider.setValue(lineHeight);
    // this is a hack as the core component doesn't support a slider. It requires a select list.
    this.emulateChangeEvent(this.lineHeightSlider, lineHeight);
  }

  protected selectSpaceBefore(space: number) {
    this.spaceBeforeInput.setValue(space);
    // this is a hack as the core component doesn't support a slider. It requires a select list.
    this.emulateChangeEvent(this.spaceBeforeInput, space);
  }

  protected selectSpaceAfter(space: number) {
    this.spaceAfterInput.setValue(space);
    // this is a hack as the core component doesn't support a slider. It requires a select list.
    this.emulateChangeEvent(this.spaceAfterInput, space);
  }

  protected selectAlign(textAlign) {
    this.alignLeft.nativeElement.checked = textAlign === 'left';
    this.alignCenter.nativeElement.checked = textAlign === 'center';
    this.alignRight.nativeElement.checked = textAlign === 'right';
  }

  public reset() {
    if (this.component) {
      this.component.reset();
    }

    const defaultStyle = this.getDefaultStyleParams();
    this.selectStyle(defaultStyle);
  }

  protected selectStyle(style) {
    const {fontSize, lineHeight, font, swatchName, textAlign,
           paragraphSpaceBefore, paragraphSpaceAfter} = style;
    this.selectFontSize(fontSize);
    this.selectLineHeight(lineHeight);
    this.selectFont(font);
    this.selectSwatch(swatchName);
    this.selectAlign(textAlign);
    this.selectSpaceBefore(paragraphSpaceBefore);
    this.selectSpaceAfter(paragraphSpaceAfter);

    const isBold = font.fontWeight === 'bold';
    const isItalic = font.fontStyle === 'italic';
    this.boldCbx.nativeElement.checked = isBold;
    this.italicCbx.nativeElement.checked = isItalic;
  }

  public hide() {
    if (this.component) {
      this.component.hide();
    }
  }

  public onResetClick() {
    this.reset();
  }
}
