import {Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ShadowPanelComponent as RefAppShadowPanelComponent} from '@spi-ref-app-angular/app/shadow-panel/shadow-panel.component';

@Component({
  selector: 'app-shadow-panel',
  templateUrl: './shadow-panel.component.html',
  styleUrls: ['./shadow-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ShadowPanelComponent extends RefAppShadowPanelComponent {

}
