import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {AlignObjectsComponent as RefAppAlignObjectsComponent} from '@spi-ref-app-angular/app/align-objects/align-objects.component';

@Component({
  selector: 'app-align-objects',
  templateUrl: './align-objects.component.html',
  styleUrls: ['./align-objects.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlignObjectsComponent extends RefAppAlignObjectsComponent {

  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }

}
