import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Context } from '../../context/context';
import { ModalService } from '../../modal.service';
import { ModalContextMenuComponent } from '../../modal-component/modal-context-menu.component';
import { AbstractComponent } from '@spi-ref-app-angular/app/abstract-component/abstract-component';


declare var SpiSdk: any;

@Component({
  selector: 'app-add-to-article-context-menu[contextMenuId]',
  templateUrl: './add-to-article-context-menu.component.html',
  styleUrls: ['./add-to-article-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddToArticleContextMenuComponent extends AbstractComponent implements OnInit {
  @ViewChild('modalContext', {static: true})
  modalContext:ModalContextMenuComponent;

  @Input('newArticleName')
  newArticleName:string = 'New Article';

  @Input('contextMenuId')
  contextMenuId:string;

  articles:any[] = [];
  selectedArticle = null;
  pageItemId = '';

  constructor(context:Context, protected modalService:ModalService) {
    super(context);
  }

  ngOnInit() {
    if (!this.contextMenuId) {
      throw new Error('Input parameter contextMenuId is required');
    }
    super.ngOnInit();
  }

  close():void {
    this.reset();
    this.modalContext.close();
  }

  menuItemClick(event, articleId, groupElementId) {
    event.stopImmediatePropagation();
    event.stopPropagation();

    this.addPageItemToArticle(articleId, groupElementId);

    this.close();
  }

  protected addPageItemToArticle(articleId, groupElementId) {
    const {groupsModel} = SpiSdk.Model;
    // articleId === 0 means that user selected a new article
    const article = groupsModel.getGroupById(articleId || this.createNewArticle());
    groupsModel.addPageItemToGroup(this.pageItemId, article, groupElementId);
  }

  protected createNewArticle():number {
    const {groupsModel} = SpiSdk.Model;
    const articleNumbers = groupsModel.getAllGroupNumbers(SpiSdk.Article.ARTICLE_TYPE);
    const articleNumber = articleNumbers && articleNumbers.length > 0 ? Math.max(...articleNumbers) + 1 : 1;
    groupsModel.addGroupToCurrentList(new SpiSdk.Article(articleNumber));

    return articleNumber;
  }

  selectArticle(articleId) {
    this.selectedArticle = this.selectedArticle == articleId ? null : articleId;
  }

  protected reset() {
    this.selectedArticle = null;
    this.pageItemId = null;
    this.articles = [];
  }

  refreshBeforeOpen() {
    if (!this.articles.length) {
      this.onControlSelected();
    }
  }

  protected onControlSelected() {
    // We don't support articles context manu for multiselect
    const selectedControl = SpiSdk.SelectionManager.getSingleSelectedControl();
    const pageItem = selectedControl ? selectedControl.pageItem : null;
    this.recreateArticleOptions(pageItem);
    this.pageItemId = pageItem ? pageItem.id : '';
    this.selectedArticle = this.articles.length ? this.articles[0].id : null;
  }

  protected recreateArticleOptions(selectedPageItem) {
    const {groupsModel} = SpiSdk.Model;
    if (!selectedPageItem || groupsModel.getGroupByPageItemId(selectedPageItem.id) !== null) {
      this.articles = [];
      return;
    }

    const newArticle = new SpiSdk.Article(0);
    newArticle.name = this.newArticleName;
    const articleOptions = groupsModel.getGroupOptionsForPageItem(selectedPageItem.id);
    articleOptions.push(newArticle);

    const {pageItemType} = selectedPageItem;
    const options = articleOptions.map(group => {
                                      const {id, name} = group;

                                      const availableGroupElements = group.getAvailableGroupElements(pageItemType);
                                      const groupElements = availableGroupElements.map(groupElement => {
                                        const {name:id, displayName, allowMultipleEntities, pageItemIds} = groupElement;
                                        const name = allowMultipleEntities
                                                      ? `${displayName} ${pageItemIds.length + 1}`
                                                      : displayName;
                                        return {id, name};
                                      });

                                      return {id, name, groupElements};
                                  });
    this.articles = options;
  }

  protected addEventListeners(): void {
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.ControlEvent.CONTROL_SELECTED, this, 'onControlSelected');
  }

  protected removeListeners(): void {
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.ControlEvent.CONTROL_SELECTED, this, 'onControlSelected');
  }
}
