import { Component, ViewEncapsulation} from '@angular/core';
import {HorizontalAlignButton as RefAppHorizontalAlignButton} from '@spi-ref-app-angular/app/horizontal-align/align-button.component';

declare var SpiSdk: any;
declare var SpiRef: any;
declare var $: any;

@Component({
  selector: 'align-button',
  template: '<button type="{{type}}" #btn><label><i class="fa"></i></label></button>'
})
export class HorizontalAlignButton extends RefAppHorizontalAlignButton {

}
