import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {Context} from '../../context/context';
import {FontLineHeightSliderFormComponent as RefAppFontLineHeightSliderFormComponent} from '@spi-ref-app-angular/app/font-line-height-slider/font-line-height-slider-form/font-line-height-slider-form.component';

@Component({
  selector: 'app-font-line-height-slider-form',
  templateUrl: '../../shared/templates/input-slider-base.component.html',
  styleUrls: ['../font-line-height-slider.component.scss']
})
export class FontLineHeightSliderFormComponent extends RefAppFontLineHeightSliderFormComponent implements OnInit {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
