import { Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Context } from '../context/context';
import { AbstractPanelComponent } from '../abstract-panel/abstract-panel.component';
import { InsetSpacingPanelComponent as RefAppInsetSpacingPanelComponent } from '@spi-ref-app-angular/app/inset-spacing-panel/inset-spacing-panel.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-inset-spacing-panel',
  templateUrl: './inset-spacing-panel.component.html',
  styleUrls: ['./inset-spacing-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class InsetSpacingPanelComponent extends RefAppInsetSpacingPanelComponent {
}
