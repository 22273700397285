import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {Context} from '../../context/context';
import {ParagraphSpaceFormComponent as RefAppParagraphSpaceFormComponent} from '@spi-ref-app-angular/app/paragraph-space/paragraph-space-form/paragraph-space-form.component';

@Component({
  selector: 'app-paragraph-space-form',
  templateUrl: '../../shared/templates/input-slider-base.component.html',
  styleUrls: ['../paragraph-space.component.scss']
})
export class ParagraphSpaceFormComponent extends RefAppParagraphSpaceFormComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
