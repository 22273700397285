import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { Context } from '../context/context';
import {InputNumberComponent as RefAppInputNumberComponent} from '@spi-ref-app-angular/app/input-number/input-number.component';

declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InputNumberComponent extends RefAppInputNumberComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
