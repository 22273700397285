import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {GuidesComponent as RefAppGuidesComponent} from '@spi-ref-app-angular/app/guides/guides.component';

declare var SpiSdk: any;
declare var SpiRef: any;
declare var $: any;

@Component({
  selector: 'app-guides',
  templateUrl: './guides.component.html',
  styleUrls: ['./guides.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class GuidesComponent extends RefAppGuidesComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
