import { Component, OnInit, ViewEncapsulation, ElementRef} from '@angular/core';
import {Context} from '../context/context';
import {SpiImageCategoriesComponent} from '../spi-image-categories/spi-image-categories.component';

@Component({
  selector: 'app-brandfolder-image-categories',
  templateUrl: './brandfolder-image-categories.component.html',
  styleUrls: ['./brandfolder-image-categories.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrandfolderImageCategoriesComponent extends SpiImageCategoriesComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
