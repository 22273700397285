import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {PanelNavigationComponent as RefAppPanelNavigationComponent} from '@spi-ref-app-angular/app/panel-navigation/panel-navigation.component';

@Component({
  selector: 'app-panel-navigation',
  templateUrl: './panel-navigation.component.html',
  styleUrls: ['./panel-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PanelNavigationComponent extends RefAppPanelNavigationComponent implements OnInit {
  constructor(context: Context, protected element: ElementRef) {
    super(context, element);
  }
}
