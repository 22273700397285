import { Component, OnInit, ViewEncapsulation, ElementRef} from '@angular/core';
import {Context} from '../context/context';
import {SpiImageCategoriesComponent as RefAppSpiImageCategoriesComponent} from '@spi-ref-app-angular/app/spi-image-categories/spi-image-categories.component';

@Component({
  selector: 'app-spi-image-categories',
  templateUrl: './spi-image-categories.component.html',
  styleUrls: ['./spi-image-categories.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpiImageCategoriesComponent extends RefAppSpiImageCategoriesComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
