import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {FontStyleBoldComponent as RefAppFontStyleBoldComponent} from '@spi-ref-app-angular/app/font-style-bold/font-style-bold.component';

@Component({
  selector: 'app-font-style-bold',
  templateUrl: './font-style-bold.component.html',
  styleUrls: ['./font-style-bold.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class FontStyleBoldComponent extends RefAppFontStyleBoldComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
