import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {AddPageItemBtnComponent as RefAppAddPageItemBtnComponent} from '@spi-ref-app-angular/app/add-page-item-btn/add-page-item-btn.component';

@Component({
  selector: 'app-add-page-item-btn',
  templateUrl: './add-page-item-btn.component.html',
  styleUrls: ['./add-page-item-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddPageItemBtnComponent extends RefAppAddPageItemBtnComponent implements OnInit {
  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }
}
