import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {Context} from '../context/context';
import {FontSizeSliderComponent as RefAppFontSizeSliderComponent} from '@spi-ref-app-angular/app/font-size-slider/font-size-slider.component';

@Component({
  selector: 'app-font-size-slider',
  templateUrl: '../shared/templates/input-slider-base.component.html',
  styleUrls: ['./font-size-slider.component.scss']
})
export class FontSizeSliderComponent extends RefAppFontSizeSliderComponent implements OnInit {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
