import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {PreviewBtnComponent as RefAppPreviewBtnComponent} from '@spi-ref-app-angular/app/preview-btn/preview-btn.component';

//dereference the SDK. This ensure we don't get duplicate SDK code in the main bundle
declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'app-preview-btn',
  templateUrl: './preview-btn.component.html',
  styleUrls: ['./preview-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreviewBtnComponent extends RefAppPreviewBtnComponent {

  constructor(context:Context, protected element: ElementRef) {
    super(context, element);
  }

}
