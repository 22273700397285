import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import {Context} from '../context/context';
import {TouchZoomDocumentViewComponent as RefAppTouchZoomDocumentViewComponent} from '@spi-ref-app-angular/app/touch-zoom-document-view/touch-zoom-document-view.component';

//dereference the SDK. This ensure we don't get duplicate SDK code in the main bundle
declare var SpiSdk:any;
declare var SpiRef:any;
declare var $:any;

@Component({
  selector: 'spitouchzoomdocview',
  templateUrl: './touch-zoom-document-view.component.html',
  styleUrls: ['./touch-zoom-document-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TouchZoomDocumentViewComponent extends RefAppTouchZoomDocumentViewComponent {

  constructor(context:Context) {
    super(context);
  }

}
