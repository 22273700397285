import {Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ShapePanelComponent as RefAppShapePanelComponent} from '@spi-ref-app-angular/app/shape-panel/shape-panel.component';

declare var SpiSdk:any;

@Component({
  selector: 'app-shape-panel',
  templateUrl: './shape-panel.component.html',
  styleUrls: ['./shape-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ShapePanelComponent extends RefAppShapePanelComponent {
}
