import {Component, ElementRef, OnInit, ViewEncapsulation, ViewChild} from '@angular/core';
import {Context} from '../context/context';
import {AbstractComponent} from '@spi-ref-app-angular/app/abstract-component/abstract-component';

declare var SpiSdk: any;

@Component({
  selector: 'app-zindex-editor',
  templateUrl: './zindex-editor.component.html',
  styleUrls: ['./zindex-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZindexEditorComponent extends AbstractComponent implements OnInit {
  @ViewChild('btn1', {static: true})
  protected btnElem1: ElementRef;
  @ViewChild('btn2', {static: true})
  protected btnElem2: ElementRef;

  protected editor = new SpiSdk.ZIndexEditor();
  public disableButtons = false;

  constructor(context: Context, protected element: ElementRef) {
    super(context);
  }

  public moveToFront(event): void {
    this.editor.changeType = SpiSdk.ZIndexEditor.FRONT;
    this.editor.updateTransforms();
  }

  public sendToBack(event): void {
    this.editor.changeType = SpiSdk.ZIndexEditor.BACK;
    this.editor.updateTransforms();
  }

  protected initView(): void {
    super.initView();
    this.initEditor();
    SpiSdk.EventDispatcher.addEventListener(SpiSdk.ControlEvent.CONTROL_SELECTED, this, 'updateComponentState');
    this.disable();
  }

  protected initEditor(): void {
    this.editor.model = SpiSdk.Model;
    this.editor.selectionManager = SpiSdk.SelectionManager;
  }

  protected enable(): void {
    this.disableButtons = false;
    this.btnElem1.nativeElement.classList.add('enable');
    this.btnElem1.nativeElement.classList.remove('disabled');
    this.btnElem2.nativeElement.classList.add('enable');
    this.btnElem2.nativeElement.classList.remove('disabled');
  }

  protected disable(): void {
    this.disableButtons = true;
    this.btnElem1.nativeElement.classList.add('disabled');
    this.btnElem1.nativeElement.classList.remove('enable');
    this.btnElem2.nativeElement.classList.add('disabled');
    this.btnElem2.nativeElement.classList.remove('enable');
  }

  protected updateComponentState(): void {
    const control = SpiSdk.SelectionManager.selectedControls.length() === 1
      ? SpiSdk.SelectionManager.getSelectedControl()
      : null;

    if (control !== null &&
      control !== undefined) {
      this.enable();
    } else {
      this.disable();
    }
  }

  protected destroy(): void {
    super.destroy();
    this.editor = null;
    SpiSdk.EventDispatcher.removeEventListener(SpiSdk.ControlEvent.CONTROL_SELECTED, this, 'updateComponentState');
  }
}
