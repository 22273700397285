import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import {Context} from '../context/context';
import {FillToggleComponent as RefAppFillToggleComponent} from '@spi-ref-app-angular/app/fill-toggle/fill-toggle.component';

@Component({
  selector: 'app-fill-toggle',
  templateUrl: './fill-toggle.component.html',
  styleUrls: ['./fill-toggle.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class FillToggleComponent extends RefAppFillToggleComponent {
  constructor(context:Context, protected element:ElementRef) {
    super(context, element);
  }
}
